import React from "react";
import { useTheme, useMediaQuery, CssBaseline } from "@mui/material";
import Header from "./Header";
import WebDrawer from "./WebDrawer";
import MobileDrawer from "./MobileDrawer";

export default function Sidebar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isMobile);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isMobile ? (
        <MobileDrawer open={open} handleDrawerClose={handleDrawerClose} />
      ) : (
        <>
          <CssBaseline />
          <Header open={open} handleDrawerOpen={handleDrawerOpen} />
          <WebDrawer open={open} handleDrawerClose={handleDrawerClose} />
        </>
      )}
    </>
  );
}
