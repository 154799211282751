import { React, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {
  Grid,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import imgUrl from "../assets/images/imgUrl";
import communityLogo from "../assets/images/mainheaderIcons/community.svg";
import unhlmLogo from "../assets/images/mainheaderIcons/unhlm.svg";
import scoreLogo from "../assets/images/mainheaderIcons/scorecard.svg";
import { useLanguage } from "../contexts/LanguageContext";
import { useLocation } from "react-router-dom";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = ({ open, handleDrawerOpen }) => {
  const { language, changeLanguage } = useLanguage();
  const location = useLocation();

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
  };
  return (
    <AppBar
      position="fixed"
      open={open}
      className={`HeaderMain ${open ? "headerOpen" : "headerClose"}`}
    >
      <Toolbar className="">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
          className="displayDesktop"
        >
          <MenuIcon className="menuIconCss" />
        </IconButton>

        <Grid
          container
          spacing={2}
          className="d-flex justify-content-start align-items-start"
        >
          <Grid item xs={12} lg={9} className="order-2">
            {[
              {
                path: "/",
                icon: imgUrl.drafTBLogo,
                text: `${
                  language === 20
                    ? "Dynamics of the Francophone African Response to Tuberculosis Dashboard"
                    : "Tableau de bord de la dynamique de la réponse de l’Afrique francophone à la tuberculose"
                }`,
              },
              {
                path: "/crg",
                icon: communityLogo,
                text: `${
                  language === 20
                    ? "DRAF TB Commuinuty, Rights and Gender Dashboard"
                    : "Tableau de bord de la communauté de lutte contre la tuberculose, des droits et de l’égalité des sexes de la DRAF"
                }`,
              },
              {
                path: "/unhlm",
                icon: unhlmLogo,
                text: `${
                  language === 20
                    ? "DRAF TB UNHLM Dashboard"
                    : "Tableau de bord de la DAF TB UNHLM"
                }`,
              },
              {
                path: "/UnhlmScorecard",
                icon: scoreLogo,
                text: `${
                  language === 20
                    ? "DRAF TB UNHLM Scorecard"
                    : "Tableau de bord DRAF TB UNHLM"
                }`,
              },
              {
                path: "/feedback",
                icon: imgUrl.drafTBLogo,
                text: `${
                  language === 20
                    ? "Comments and Feedback"
                    : "Commentaires et réactions"
                }`,
              },
              {
                path: "/CRGAssessmentDashboard",
                icon: communityLogo,
                text: `${
                  language === 20
                    ? "DRAF TB CRG Country Assessment Dashboard"
                    : "Tableau de bord de l’évaluation nationale du GRC de la DGRAF"
                }`,
              },
              {
                path: "/CRGActionPlanDashboard",
                icon: communityLogo,
                text: `${
                  language === 20
                    ? "DRAF TB CRG Country Action Plan Dashboard"
                    : "Tableau de bord du plan d’action par pays DRAF TB CRG"
                }`,
              },
            ].map(({ path, icon, text }) => (
              <>
                {location.pathname == path && (
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={2}
                      className="pt-0px d-flex align-items-center"
                    >
                      <div>
                        <img src={icon} className="logoImg max-width-100"></img>
                      </div>
                    </Grid>
                    <Grid item xs={10} className="pt-0px pl-0px">
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        className="headerHeading"
                      >
                        <span className="headerSubHeading">{text}</span>
                      </Typography>
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        className="headerSubHeading headerUpdatedDate"
                      >
                        <span>
                          {" "}
                          {language == 20
                            ? "Last Updated"
                            : "Dernière mise à jour"}
                          : 12 May 2024
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            ))}
          </Grid>
          <Grid item xs={12} lg={3} className="order-1">
            <div className="headerMainButtons">
              <FormControl fullWidth className="languageSelectField">
                <Select
                  labelId="demo-language-select-label"
                  id="demo-language-select"
                  value={language}
                  label=""
                  onChange={handleLanguageChange}
                >
                  <MenuItem value={10} disabled>
                    <div className="d-flex justify-content-start align-items-center">
                      <img
                        src={imgUrl.translationIcon}
                        className="languageIcon"
                      ></img>
                      <span className="ml-10px languageIcon placeHolder">
                        Language{" "}
                      </span>
                    </div>

                    {/* Placeholder text or label for the initial option */}
                  </MenuItem>

                  <MenuItem value={20}>English</MenuItem>
                  <MenuItem value={30}>Français​</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
