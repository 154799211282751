import React from "react";
import { Box } from "@mui/material";
import Sidebar from "../../../components/Sidebar";
import UNHLMAccountabilityDashboardPage from "./UNHLMAccountabilityDashboardPage";

function UNHLMAccountabilityDashboard() {
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar></Sidebar>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        className="mainStructurePage pt-0px"
      >
        <UNHLMAccountabilityDashboardPage></UNHLMAccountabilityDashboardPage>
      </Box>
    </Box>
  );
}

export default UNHLMAccountabilityDashboard;
