import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";

// icons import
import MenuIcon from "@mui/icons-material/Menu";
import homeIcon from "../assets/images/sidebarIcons/home.svg";
import communityIcon from "../assets/images/sidebarIcons/social-justice.svg";
import targetIcon from "../assets/images/sidebarIcons/target.svg";
import feedbackIcon from "../assets/images/sidebarIcons/feedback.svg";
import scoreCardIcon from "../assets/images/sidebarIcons/scorecard.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useLanguage } from "../contexts/LanguageContext";
import communityLogo from "../assets/images/mainheaderIcons/community.svg";
import imgUrl from "../assets/images/imgUrl";

const drawerWidth = 240;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

function MobileDrawer(props) {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route

  const { language, changeLanguage } = useLanguage();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const isActive = (path) => {
    if (path === "/crg") {
      return (
        location.pathname === "/crg" ||
        location.pathname.startsWith("/crg/") ||
        location.pathname === "/CRGAssessmentDashboard" ||
        location.pathname === "/CRGActionPlanDashboard"
      );
    }
    return location.pathname === path;
  };

  const drawer = (
    <div>
      <DrawerHeader className="mt-40px mb-25px">
        <div className="d-flex justify-content-center align-items-center mb-10px">
          <img
            src={imgUrl.drafTBLogo}
            className="logoImg max-width-100"
            alt="logo"
          />
        </div>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <List className="sideListSection pt-0px">
        {[
          { path: "/", icon: homeIcon, text: "Home Page" },
          {
            path: "/crg",
            icon: communityIcon,
            text: "Community, Rights and Gender",
          },
          { path: "/unhlm", icon: targetIcon, text: "UNHLM" },
          {
            path: "/UnhlmScorecard",
            icon: scoreCardIcon,
            text: "UNHLM Scorecard",
          },
          { path: "/feedback", icon: feedbackIcon, text: "Feedback" },
        ].map(({ path, icon, text }) => (
          <ListItem key={path} disablePadding>
            <ListItemButton
              onClick={() => navigate(path)}
              sx={{
                backgroundColor: isActive(path) ? "#F8DEBD6B" : "inherit", // Apply a background color for active state
                "&:hover": {
                  backgroundColor: isActive(path)
                    ? "rgba(0, 0, 0, 0.12)"
                    : "rgba(0, 0, 0, 0.04)", // Change background color on hover
                },
              }}
            >
              <ListItemIcon>
                <img src={icon} className="sideIcon max-width-100" alt="icon" />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <div className="sidebarFooter">
        <div className="d-flex justify-content-center align-items-center flex-direction-column sidebarBottomLogos">
          <img src={imgUrl.oneImpactLogo} alt="logo" />
          <img src={imgUrl.stopTBLogo} className="mt-20px" alt="logo" />
        </div>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="HeaderMain"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <Grid
            container
            spacing={2}
            className="d-flex justify-content-start align-items-start"
          >
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4} className="pt-0px d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ mr: 2, display: { sm: "none" } }}
                    >
                      <MenuIcon className="menuIconCss" />
                    </IconButton>
                    {[
                      {
                        path: "/",
                        icon: imgUrl.drafTBLogo,
                        text: `${
                          language === 20
                            ? "Dynamics of the Francophone African Response to Tuberculosis Dashboard"
                            : "Tableau de bord de la dynamique de la réponse de l’Afrique francophone à la tuberculose"
                        }`,
                      },
                      {
                        path: "/crg",
                        icon: communityLogo,
                        text: `${
                          language === 20
                            ? "DRAF TB Commuinuty, Rights and Gender Dashboard"
                            : "Tableau de bord de la communauté de lutte contre la tuberculose, des droits et de l’égalité des sexes de la DRAF"
                        }`,
                      },
                      {
                        path: "/unhlm",
                        icon: imgUrl.unhlmLogo,
                        text: `${
                          language === 20
                            ? "DRAF TB UNHLM Dashboard"
                            : "Tableau de bord de la DAF TB UNHLM"
                        }`,
                      },
                      {
                        path: "/UnhlmScorecard",
                        icon: imgUrl.scoreLogo,
                        text: `${
                          language === 20
                            ? "DRAF TB UNHLM Scorecard"
                            : "Tableau de bord DRAF TB UNHLM"
                        }`,
                      },
                      {
                        path: "/feedback",
                        icon: imgUrl.drafTBLogo,
                        text: `${
                          language === 20
                            ? "Comments and Feedback"
                            : "Commentaires et réactions"
                        }`,
                      },
                      {
                        path: "/CRGAssessmentDashboard",
                        icon: communityLogo,
                        text: `${
                          language === 20
                            ? "DRAF TB CRG Country Assessment Dashboard"
                            : "Tableau de bord de l’évaluation nationale du GRC de la DGRAF"
                        }`,
                      },
                      {
                        path: "/CRGActionPlanDashboard",
                        icon: communityLogo,
                        text: `${
                          language === 20
                            ? "DRAF TB CRG Country Action Plan Dashboard"
                            : "Tableau de bord du plan d’action par pays DRAF TB CRG"
                        }`,
                      },
                    ].map(({ path, icon, text }) => (
                      <>
                        {location.pathname == path && (
                          <img
                            src={icon}
                            className="logoImg max-width-100"
                            alt="logo"
                          />
                        )}
                      </>
                    ))}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={8}
                  className="pt-0px align-items-center justify-content-end"
                >
                  <div className="headerMainButtons">
                    <FormControl fullWidth className="languageSelectField">
                      <Select
                        labelId="demo-language-select-label"
                        id="demo-language-select"
                        value={language}
                        label=""
                        onChange={handleLanguageChange}
                      >
                        <MenuItem value={10} disabled>
                          <div className="d-flex justify-content-start align-items-center">
                            <img
                              src={imgUrl.translationIcon}
                              className="languageIcon"
                              alt="translation icon"
                            />
                            <span className="ml-10px languageIcon placeHolder">
                              Language{" "}
                            </span>
                          </div>
                        </MenuItem>
                        <MenuItem value={20}>English</MenuItem>
                        <MenuItem value={30}>Français​</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} className="pt-0px mt-10px">
                  {[
                    {
                      path: "/",
                      icon: imgUrl.drafTBLogo,
                      text: `${
                        language === 20
                          ? "Dynamics of the Francophone African Response to Tuberculosis Dashboard"
                          : "Tableau de bord de la dynamique de la réponse de l’Afrique francophone à la tuberculose"
                      }`,
                    },
                    {
                      path: "/crg",
                      icon: communityLogo,
                      text: `${
                        language === 20
                          ? "DRAF TB Commuinuty, Rights and Gender Dashboard"
                          : "Tableau de bord de la communauté de lutte contre la tuberculose, des droits et de l’égalité des sexes de la DRAF"
                      }`,
                    },
                    {
                      path: "/unhlm",
                      icon: imgUrl.unhlmLogo,
                      text: `${
                        language === 20
                          ? "DRAF TB UNHLM Dashboard"
                          : "Tableau de bord de la DAF TB UNHLM"
                      }`,
                    },
                    {
                      path: "/UnhlmScorecard",
                      icon: imgUrl.scoreLogo,
                      text: `${
                        language === 20
                          ? "DRAF TB UNHLM Scorecard"
                          : "Tableau de bord DRAF TB UNHLM"
                      }`,
                    },
                    {
                      path: "/feedback",
                      icon: imgUrl.drafTBLogo,
                      text: `${
                        language === 20
                          ? "Comments and Feedback"
                          : "Commentaires et réactions"
                      }`,
                    },
                    {
                      path: "/CRGAssessmentDashboard",
                      icon: communityLogo,
                      text: `${
                        language === 20
                          ? "DRAF TB CRG Country Assessment Dashboard"
                          : "Tableau de bord de l’évaluation nationale du GRC de la DGRAF"
                      }`,
                    },
                    {
                      path: "/CRGActionPlanDashboard",
                      icon: communityLogo,
                      text: `${
                        language === 20
                          ? "DRAF TB CRG Country Action Plan Dashboard"
                          : "Tableau de bord du plan d’action par pays DRAF TB CRG"
                      }`,
                    },
                  ].map(({ path, icon, text }) => (
                    <>
                      {location.pathname == path && (
                        <Typography
                          variant="h6"
                          noWrap
                          component="div"
                          className="headerHeading"
                        >
                          <span className="headerSubHeading">{text}</span>
                        </Typography>
                      )}
                    </>
                  ))}
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    className="headerSubHeading headerUpdatedDate"
                  >
                    <span>
                      {" "}
                      {language == 20 ? "Last Updated" : "Dernière mise à jour"}
                      : 12 May 2024
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          className="sidebarSection"
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

MobileDrawer.propTypes = {
  window: PropTypes.func,
};

export default MobileDrawer;
