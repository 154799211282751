export default {
  drafTBLogo: require("../images/draftblogo.png"),
  oneImpactLogo: require("../images/oneimpactlogo.png"),
  stopTBLogo: require("../images/stoptblogo.png"),
  // sidebar icons
  //   homeIcon: require("../images/sidebarIcons/home.svg"),
  //   communityIcon: require("../images/sidebarIcons/social-justice.png"),
  //   targetIcon: require("../images/sidebarIcons/target.png"),
  //   feedbackIcon: require("../images/sidebarIcons/feedback.png"),
  //   countryImages
  beninLogo: require("../images/countryimages/benin.png"),
  cameroonLogo: require("../images/countryimages/cameroon.png"),
  rdcLogo: require("../images/countryimages/democratic-republic-of-congo.png"),
  congoLogo: require("../images/countryimages/republic-of-the-congo.png"),
  nigerLogo: require("../images/countryimages/niger.png"),
  burkinaLogo: require("../images/countryimages/burkina-faso.png"),
  guineeLogo: require("../images/countryimages/guinea.png"),
  burundiLogo: require("../images/countryimages/burundi.png"),
  togoLogo: require("../images/countryimages/togo.png"),
  maliLogo: require("../images/countryimages/mali.png"),
  mauritanieLogo: require("../images/countryimages/mauritania.png"),
  chadLogo: require("../images/countryimages/chad.png"),
  senegalLogo: require("../images/countryimages/senegal.png"),
  gabonLogo: require("../images/countryimages/gabon.png"),
  centrafriqueLogo: require("../images/countryimages/central-african-republic.png"),
  coteLogo: require("../images/countryimages/ivory-coast.png"),
  translationIcon: require("../images/headerIcons/translation.png"),

  // mainHeaderIcons
  communityLogo: require("../images/mainheaderIcons/community.svg"),
  unhlmLogo: require("../images/mainheaderIcons/unhlm.png"),
  scoreLogo: require("../images/mainheaderIcons/scorecard.png"),
};
