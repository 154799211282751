import { React, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import scoreLevelIcon from "../../assets/images/unhlmscorepageIcons/speedometer.svg";
import imgUrl from "../../assets/images/imgUrl";
import { useLanguage } from "../../contexts/LanguageContext";
import unlhmscorecardtranslation from "../../config/unlhmscorecardtranslation";

function UNHLMScoreCountrySelect() {
  // select country component
  const [scoreLevel, setScoreLevel] = useState(10); // Set the initial value to 10 for "Select country"
  const { language } = useLanguage();
  const t = (key) => unlhmscorecardtranslation[language][key] || key;
  const handleScoreLevelChange = (event) => {
    setScoreLevel(event.target.value);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Card>
            <CardContent>
              <Grid
                container
                spacing={2}
                className="d-flex justify-content-center align-items-center"
              >
                <Grid item xs={1}></Grid>

                <Grid item xs={12} lg={6}>
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={scoreLevelIcon}></img>
                    <Typography className="ml-15px">
                      {t("card2description")}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} lg={4}>
                  {" "}
                  <div className="countryDropdown mt-0px">
                    <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
                      <SearchIcon className="searchIcon"></SearchIcon>
                      <FormControl fullWidth className="countrySelectField">
                        {/* <InputLabel id="demo-simple-select-label">
                          Age
                        </InputLabel> */}
                        <Select
                          labelId="demo-scoreLevel-select-label"
                          id="demo-scoreLevel-select"
                          value={scoreLevel}
                          label=""
                          placeholder="Select Country"
                          onChange={handleScoreLevelChange}
                        >
                          <MenuItem value={10} disabled>
                            {t("selectcountry")}
                          </MenuItem>
                          <MenuItem value={20}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.cameroonLogo}></img>
                              <span className="ml-10px countryName">
                                Cameroon
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={30}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.beninLogo}></img>
                              <span className="ml-10px countryName">Benin</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={40}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.rdcLogo}></img>
                              <span className="ml-10px countryName">RDC</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={50}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.congoLogo}></img>
                              <span className="ml-10px countryName">Congo</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={60}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.nigerLogo}></img>
                              <span className="ml-10px countryName">Niger</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={70}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.burkinaLogo}></img>
                              <span className="ml-10px countryName">
                                Burkina Faso
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={80}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.guineeLogo}></img>
                              <span className="ml-10px countryName">
                                Guinée
                              </span>
                            </div>
                          </MenuItem>{" "}
                          <MenuItem value={90}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.burundiLogo}></img>
                              <span className="ml-10px countryName">
                                Burundi
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={100}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.togoLogo}></img>
                              <span className="ml-10px countryName">Togo</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={101}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.maliLogo}></img>
                              <span className="ml-10px countryName">Mali</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={102}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.mauritanieLogo}></img>
                              <span className="ml-10px countryName">
                                Mauritanie
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={103}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.chadLogo}></img>
                              <span className="ml-10px countryName">Chad</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={104}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.senegalLogo}></img>
                              <span className="ml-10px countryName">
                                Sénégal
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={105}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.gabonLogo}></img>
                              <span className="ml-10px countryName">Gabon</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={106}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.centrafriqueLogo}></img>
                              <span className="ml-10px countryName">
                                Centrafrique
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={106}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.coteLogo}></img>
                              <span className="ml-10px countryName">
                                Côte d’Ivoire
                              </span>
                            </div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default UNHLMScoreCountrySelect;
