const translations = {
  20: {
    countriesInDRAF: "Countries in DRAF",
    selectCountry:
      "Select a country from the map or drop down menu to see key TB statistics.",
    estimatedTBCases: "Estimated number of people who developed TB",
    since: "since",
    lastYear: "last year",
    estimatedDeaths: "Estimated number of people who died from TB",
    lastMonth: "last month",
    estimatedDrugResistant: "Estimated number of people with drug resistant TB",
    tbTreatmentCoverage: "TB Treatment Coverage",
    tbAndHIVEstimates: "Estimates people who have TB and HIV",
    tbAndHIVDeaths: "Estimates people who were have died with TB and HIV",
    unhmlScoreCard: "UNHLM Post-Activities Score Card 2023",
    score: "7.6/10",
    drafTbHeading: "DRAF - TB",
    drafTbContent1: `According to the Cotonou Declaration on Tuberculosis; the West
      and Central Africa (WCA) region fails to detect more than 50%
      of TB cases, three quarters of cases affecting children are
      not detected; records an estimated mortality rate among TB/HIV
      co-infected patients 50% higher than in the rest of Africa,
      only 20% of patients with drug-resistant TB are detected and
      treated.`,
    drafTbContent2: `Aware of the challenges, the leaders of civil society in the
      region, gathered on the sidelines of the meeting of the
      Anglophone Africa Platform of the CRG, on April 24, 2018, in
      Accra-Ghana, made a commitment to contribute to increasing for
      everyone, but especially for vulnerable and stigmatized
      groups, coverage and access to anti-tuberculosis services.`,
    drafTbContent3: `To this end, the "Dynamics of the Francophone African Response
      to Tuberculosis (TB)" (DRAF-TB) as a regional coalition of
      communities affected by TB has been set up. The "DRAF-TB"
      coalition requires an inclusive meeting with the countries
      represented to define its operating methods and the priority
      areas of its future actions. Some twenty countries in the
      region mobilized for this purpose during the African regional
      meeting on tuberculosis, which was held from 22 to 24 May 2018
      in Johannesburg (South Africa).`,
    drafTbContent4: `This dashboard helps to summarize and visualize the data
      collected by each DRAF country on Community, Rights, Gender
      (CRG) and the United Nations High Level Meeting (UNHLM) Key
      Asks for TB.`,
  },
  30: {
    countriesInDRAF: "Pays dans la DRAF",
    selectCountry:
      "Sélectionnez un pays sur la carte ou dans le menu déroulant pour afficher les principales statistiques sur la tuberculose.",
    estimatedTBCases:
      "Nombre estimé de personnes ayant contracté la tuberculose",
    since: "depuis",
    lastYear: " l’année dernière",
    estimatedDeaths:
      "Estimation du nombre de personnes décédées de la tuberculose",
    lastMonth: "le mois dernier",
    estimatedDrugResistant:
      "Estimation du nombre de personnes atteintes de tuberculose pharmacorésistante",
    tbTreatmentCoverage: "Couverture du traitement de la tuberculose",
    tbAndHIVEstimates:
      "Estimations du nombre de personnes atteintes de tuberculose et du VIH",
    tbAndHIVDeaths:
      "Estime que les personnes décédées de la tuberculose et du VIH sont mortes",
    unhmlScoreCard:
      "Tableau de bord post-activités 2023 de la Réunion de haut niveau de l’ONU",
    score: "7.6/10",
    drafTbHeading: "DRAF - TB",
    drafTbContent1: `Selon la Déclaration de Cotonou sur la tuberculose ; l’Ouest
      et la région de l’Afrique centrale (AOC) ne parvient pas à détecter plus de 50 %
      des cas de tuberculose, les trois quarts des cas affectant les enfants
      non détecté ; enregistre une estimation du taux de mortalité parmi la tuberculose/VIH
      patients co-infectés 50% plus élevés que dans le reste de l’Afrique,
      seulement 20 % des patients atteints de tuberculose pharmacorésistante sont détectés et
      Traités.`,
    drafTbContent2: `Conscients des enjeux, les leaders de la société civile
      , réunis en marge de la réunion des
      Plateforme Afrique anglophone du CRG, le 24 avril 2018, à
      Accra-Ghana, s’est engagé à contribuer à l’augmentation de la
      tout le monde, mais surtout les personnes vulnérables et stigmatisées
      groupes, la couverture et l’accès aux services antituberculeux.`,
    drafTbContent3: `À cette fin, la « Dynamique de la réponse de l’Afrique francophone
      à la tuberculose (TB) » (DRAF-TB) en tant que coalition régionale de
      des communautés touchées par la tuberculose a été mise en place. La « DRAF-TB »
      La coalition exige une réunion inclusive avec les pays
      représentée pour définir ses modes de fonctionnement et la priorité
      domaines de ses actions futures. Une vingtaine de pays du
      mobilisée à cet effet lors de la
      sur la tuberculose, qui s’est tenue du 22 au 24 mai 2018
      à Johannesburg (Afrique du Sud).`,
    drafTbContent4: `Ce tableau de bord permet de résumer et de visualiser les données
      collectées par chaque pays de la DRAF sur la communauté, les droits, le genre
      (CRG) et la Réunion de haut niveau des Nations Unies (UNHLM)
      Demande la tuberculose.`,
  },
};

export default translations;
