import { React, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import UNHLMCountrySelect from "../UNHLMCountrySelect";

// icons import
import reachIcon from "../../../assets/images/unhlmpageIcons/reach.svg";
import justiceIcon from "../../../assets/images/unhlmpageIcons/justice.svg";
import medicineIcon from "../../../assets/images/unhlmpageIcons/syringe.svg";
import fundingIcon from "../../../assets/images/unhlmpageIcons/funding.svg";
import hospitalIcon from "../../../assets/images/unhlmpageIcons/hospital.svg";
import worldIcon from "../../../assets/images/unhlmpageIcons/worldwide.svg";
import ActivitiesIcon from "../../../assets/images/unhlmpageIcons/activity.svg";
import imgUrl from "../../../assets/images/imgUrl";
import SearchIcon from "@mui/icons-material/Search";

function UNHLMAccountabilityDashboardPage() {
  const [countryActivities, setCountryActivities] = useState(10); // Set the initial value to 10 for "Select country"
  const [UNHLMAccCountries, setUNHLMAccCountries] = useState(10); // Set the initial value to 10 for "Select country"
  const handleCountryActivitiesChange = (event) => {
    setCountryActivities(event.target.value);
  };

  const handlesUNHLMAccCountriesChange = (event) => {
    setUNHLMAccCountries(event.target.value);
  };

  return (
    <div className="commonPageCardSubSection">
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3} className="subCountries-mobile-d-none">
            <Card className="defineCountryHeight">
              <CardContent>
                <div>
                  <Typography className="text-center listCountryHeader">
                    Countries
                  </Typography>
                  <div className="listCountries">
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <img src={imgUrl.cameroonLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Cameroon" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.beninLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Benin" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.rdcLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="RDC" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.congoLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Congo" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.nigerLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Niger" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.burkinaLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Burkina Faso" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.guineeLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Guinée" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.burundiLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Burundi" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.togoLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Togo" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.maliLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Mali" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.mauritanieLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Mauritanie" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.chadLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Chad" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.senegalLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Sénégal" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.gabonLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Gabon" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.centrafriqueLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Centrafrique" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.coteLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Côte d’Ivoire" />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="mt-10px defineReportHeight">
              <CardContent>
                <Typography className="reportHeading">
                  To see the details of this report follow the link below:{" "}
                </Typography>
                <Link
                  href="https://stoptb.org/assets/documents/global/awards/cfcs/r9/20268_Factsheet_DRAF_TB_v02_JG_001.pdf"
                  className="reportLink"
                >
                  https://stoptb.org/assets/documents/global/awards/cfcs/r9/20268_Factsheet_DRAF_TB_v02_JG_001.pdf
                </Link>
              </CardContent>
            </Card>
          </Grid>

          {/* mobile ui for countries */}
          <Grid item xs={12} className="subCountries-desktop-d-none">
            <div className="countryDropdown mt-0px">
              <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
                <SearchIcon className="searchIcon"></SearchIcon>
                <FormControl fullWidth className="countrySelectField">
                  {/* <InputLabel id="demo-simple-select-label">
                          Age
                        </InputLabel> */}
                  <Select
                    labelId="demo-UNHLMAccCountries-select-label"
                    id="demo-UNHLMAccCountries-select"
                    value={UNHLMAccCountries}
                    label=""
                    onChange={handlesUNHLMAccCountriesChange}
                  >
                    <MenuItem value={10} disabled>
                      Select Country
                      {/* Placeholder text or label for the initial option */}
                    </MenuItem>
                    <MenuItem value={20}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.cameroonLogo}></img>
                        <span className="ml-10px countryName">Cameroon</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={30}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.beninLogo}></img>
                        <span className="ml-10px countryName">Benin</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={40}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.rdcLogo}></img>
                        <span className="ml-10px countryName">RDC</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={50}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.congoLogo}></img>
                        <span className="ml-10px countryName">Congo</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={60}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.nigerLogo}></img>
                        <span className="ml-10px countryName">Niger</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={70}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.burkinaLogo}></img>
                        <span className="ml-10px countryName">
                          Burkina Faso
                        </span>
                      </div>
                    </MenuItem>
                    <MenuItem value={80}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.guineeLogo}></img>
                        <span className="ml-10px countryName">Guinée</span>
                      </div>
                    </MenuItem>{" "}
                    <MenuItem value={90}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.burundiLogo}></img>
                        <span className="ml-10px countryName">Burundi</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={100}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.togoLogo}></img>
                        <span className="ml-10px countryName">Togo</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={101}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.maliLogo}></img>
                        <span className="ml-10px countryName">Mali</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={102}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.mauritanieLogo}></img>
                        <span className="ml-10px countryName">Mauritanie</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={103}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.chadLogo}></img>
                        <span className="ml-10px countryName">Chad</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={104}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.senegalLogo}></img>
                        <span className="ml-10px countryName">Sénégal</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={105}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.gabonLogo}></img>
                        <span className="ml-10px countryName">Gabon</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={106}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.centrafriqueLogo}></img>
                        <span className="ml-10px countryName">
                          Centrafrique
                        </span>
                      </div>
                    </MenuItem>
                    <MenuItem value={106}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.coteLogo}></img>
                        <span className="ml-10px countryName">
                          Côte d’Ivoire
                        </span>
                      </div>
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>{" "}
            <Card className="mt-10px defineReportHeight mobile-DataLink">
              <CardContent>
                <Typography className="reportHeading">
                  To see the details of this report follow the link below:{" "}
                </Typography>
                <Link
                  href="https://stoptb.org/assets/documents/global/awards/cfcs/r9/20268_Factsheet_DRAF_TB_v02_JG_001.pdf"
                  className="reportLink"
                >
                  https://stoptb.org/assets/documents/global/awards/cfcs/r9/20268_Factsheet_DRAF_TB_v02_JG_001.pdf
                </Link>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Card className="defineCountryInfoHeight">
              <CardContent>
                <div className="">
                  <Typography className="unhlmHeading">
                    {" "}
                    UNHLM TB Key Asks Country Accountability{" "}
                  </Typography>
                  <div className="keyPara mt-10px">
                    <Card className="bg-pink">
                      <CardContent>
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            <img src={reachIcon}></img>
                          </div>

                          <div className="ml-15px">
                            <Typography>Key Ask # 1</Typography>
                            <p className="mb-0px keyParaSection">Example</p>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                    <Card className="bg-blue mt-10px">
                      <CardContent>
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            <img src={justiceIcon}></img>
                          </div>

                          <div className="ml-15px">
                            <Typography>Key Ask # 2</Typography>
                            <p className="mb-0px keyParaSection">Example</p>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                    <Card className="bg-orange mt-10px">
                      <CardContent>
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            <img src={medicineIcon}></img>
                          </div>

                          <div className="ml-15px">
                            <Typography>Key Ask # 3</Typography>
                            <p className="mb-0px keyParaSection">Example</p>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                    <Card className="bg-pink mt-10px">
                      <CardContent>
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            <img src={fundingIcon}></img>
                          </div>

                          <div className="ml-15px">
                            <Typography>Key Ask # 4</Typography>
                            <p className="mb-0px keyParaSection">Example</p>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                    <Card className="bg-blue mt-10px">
                      <CardContent>
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            <img src={hospitalIcon}></img>
                          </div>

                          <div className="ml-15px">
                            <Typography>Key Ask # 5</Typography>
                            <p className="mb-0px keyParaSection">Example</p>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                    <Card className="bg-orange mt-10px">
                      <CardContent>
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            <img src={worldIcon}></img>
                          </div>

                          <div className="ml-15px">
                            <Typography>Key Ask # 6</Typography>
                            <p className="mb-0px keyParaSection">Example</p>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      {/* second country select section */}
      <div className="mt-10px">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Grid item xs={1}></Grid>

                  <Grid item xs={12} md={6} sm={12} lg={6}>
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={ActivitiesIcon} alt="location"></img>
                      <Typography className="ml-15px">
                        Go to Country Key Asks Activities
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4} sm={12} lg={4}>
                    {" "}
                    <div className="countryDropdown mt-0px">
                      <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
                        <SearchIcon className="searchIcon"></SearchIcon>
                        <FormControl fullWidth className="countrySelectField">
                          {/* <InputLabel id="demo-simple-select-label">
                          Age
                        </InputLabel> */}
                          <Select
                            labelId="demo-countryActivities-select-label"
                            id="demo-countryActivities-select"
                            value={countryActivities}
                            label=""
                            onChange={handleCountryActivitiesChange}
                          >
                            <MenuItem value={10} disabled>
                              Select Country
                              {/* Placeholder text or label for the initial option */}
                            </MenuItem>
                            <MenuItem value={20}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.cameroonLogo}></img>
                                <span className="ml-10px countryName">
                                  Cameroon
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={30}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.beninLogo}></img>
                                <span className="ml-10px countryName">
                                  Benin
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={40}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.rdcLogo}></img>
                                <span className="ml-10px countryName">RDC</span>
                              </div>
                            </MenuItem>
                            <MenuItem value={50}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.congoLogo}></img>
                                <span className="ml-10px countryName">
                                  Congo
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={60}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.nigerLogo}></img>
                                <span className="ml-10px countryName">
                                  Niger
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={70}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.burkinaLogo}></img>
                                <span className="ml-10px countryName">
                                  Burkina Faso
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={80}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.guineeLogo}></img>
                                <span className="ml-10px countryName">
                                  Guinée
                                </span>
                              </div>
                            </MenuItem>{" "}
                            <MenuItem value={90}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.burundiLogo}></img>
                                <span className="ml-10px countryName">
                                  Burundi
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={100}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.togoLogo}></img>
                                <span className="ml-10px countryName">
                                  Togo
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={101}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.maliLogo}></img>
                                <span className="ml-10px countryName">
                                  Mali
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={102}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.mauritanieLogo}></img>
                                <span className="ml-10px countryName">
                                  Mauritanie
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={103}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.chadLogo}></img>
                                <span className="ml-10px countryName">
                                  Chad
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={104}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.senegalLogo}></img>
                                <span className="ml-10px countryName">
                                  Sénégal
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={105}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.gabonLogo}></img>
                                <span className="ml-10px countryName">
                                  Gabon
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={106}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.centrafriqueLogo}></img>
                                <span className="ml-10px countryName">
                                  Centrafrique
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={107}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.coteLogo}></img>
                                <span className="ml-10px countryName">
                                  Côte d’Ivoire
                                </span>
                              </div>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default UNHLMAccountabilityDashboardPage;
