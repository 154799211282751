const unlhmtranslation = {
  20: {
    card1description: `United Nations High Level Meeting TB Key Asks.`,
    selectcountry: "Select Country",
    dropdown1title: "Country Key Asks Accountability",
    dropdown2title: "Country Key Asks Activities",
    keyask:"Key Ask",
    card1: `Reach all people affected by tuberculosis (TB) with prevention, diagnosis, treatment, and care by implementing evidence-based and quality interventions and tools as per the latest international guidelines.`,
    card2:
      "Ensure all national TB responses are equitable, inclusive, gender-sensitive, rights-based and people-centered.",
    card3: `Accelerate the research, development, roll-out, and access to new TB vaccines, diagnostics, drugs, and other essential new tools, including digital health technologies geared to the needs of the most neglected, key and vulnerable populations.`,
    card4: "Invest the funds necessary to End TB.",
    card5: `Prioritize TB across systems for health: Universal Health Coverage (UHC), Primary Health Care (PHC), Pandemic Prevention, Preparedness and Response (PPPR), and Antimicrobial Resistance (AMR).`,
    card6:
      "Ensure decisive and accountable global, regional and national leadership, including regular UN reporting and Review.",
  },
  30: {
    card1description: `Tableau de bord de la lutte contre la tuberculose de la Réunion de haut niveau des Nations Unies.`,
    selectcountry: "Sélectionner un pays",
    dropdown1title: "Country Key demande des comptes",
    dropdown2title: "La clé du pays demande des activités",
    keyask:"Demande clé",
    card1: `Atteindre toutes les personnes touchées par la tuberculose (TB) en matière de prévention, de diagnostic, de traitement et de soins en mettant en œuvre des interventions et des outils de qualité fondés sur des données probantes, conformément aux dernières directives internationales.`,
    card2:
      "Veiller à ce que toutes les ripostes nationales à la tuberculose soient équitables, inclusives, sensibles au genre, fondées sur les droits et centrées sur les personnes.",
    card3: `Accélérer la recherche, le développement, le déploiement et l’accès à de nouveaux vaccins, diagnostics, médicaments et autres outils essentiels contre la tuberculose, y compris les technologies de santé numériques axées sur les besoins des populations les plus négligées, clés et vulnérables.`,
    card4: "Investir les fonds nécessaires pour mettre fin à la tuberculose.",
    card5: `Donner la priorité à la tuberculose dans l’ensemble des systèmes de santé : couverture sanitaire universelle (CSU), soins de santé primaires (SSP), prévention, préparation et riposte aux pandémies (PPPR) et résistance aux antimicrobiens (RAM).`,
    card6:
      "Assurer un leadership mondial, régional et national décisif et responsable, y compris des rapports et des examens réguliers de l’ONU.",
  },
};

export default unlhmtranslation;
