import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import homeIcon from "../assets/images/sidebarIcons/home.svg";
import communityIcon from "../assets/images/sidebarIcons/social-justice.svg";
import targetIcon from "../assets/images/sidebarIcons/target.svg";
import feedbackIcon from "../assets/images/sidebarIcons/feedback.svg";
import scoreCardIcon from "../assets/images/sidebarIcons/scorecard.svg";
import imgUrl from "../assets/images/imgUrl";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function WebDrawer({ open, handleDrawerClose }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to determine if the current path is active
  const isActive = (path) => {
    if (path === "/crg") {
      return (
        location.pathname === "/crg" ||
        location.pathname.startsWith("/crg/") ||
        location.pathname === "/CRGAssessmentDashboard" ||
        location.pathname === "/CRGActionPlanDashboard"
      );
    }
    return location.pathname === path;
  };

  return (
    <Drawer
      className={`sidebarSection ${open ? "sidebarOpen" : "sidebarClose"}`}
      variant="permanent"
      open={open}
    >
      <DrawerHeader className="mt-40px mb-25px">
        <div className="d-flex justify-content-center align-items-center mb-10px">
          <img
            src={imgUrl.drafTBLogo}
            className="logoImg max-width-100"
            alt="logo"
          />
        </div>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <List className="sideListSection pt-0px">
        {[
          { path: "/", icon: homeIcon, text: "Home Page" },
          {
            path: "/crg",
            icon: communityIcon,
            text: "Community, Rights and Gender",
          },
          { path: "/unhlm", icon: targetIcon, text: "UNHLM" },
          {
            path: "/UnhlmScorecard",
            icon: scoreCardIcon,
            text: "UNHLM Scorecard",
          },
          { path: "/feedback", icon: feedbackIcon, text: "Feedback" },
        ].map(({ path, icon, text }) => (
          <ListItem key={path} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: isActive(path) ? "#F8DEBD6B" : "inherit",
              }}
              onClick={() => navigate(path)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={icon} className="sideIcon max-width-100" alt="icon" />
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <div className="sidebarFooter">
        <div className="d-flex justify-content-center align-items-center flex-direction-column sidebarBottomLogos">
          <img src={imgUrl.oneImpactLogo} alt="logo" />
          <img src={imgUrl.stopTBLogo} className="mt-20px" alt="logo" />
        </div>
      </div>
    </Drawer>
  );
}
