const crgtranslation = {
  20: {
    card1description: `Key Components of CRG.`,
    selectcountry: "Select Country",
    dropdown1title: "Country Level Assessments",
    dropdown2title: "Country Level Action Plan",
    card1: `Protect and promote the right to the enjoyment of the highest attainable standard of physical and mental health, and the right to enjoy the benefits of scientific progress and its application to advance towards universal access to quality, affordable, inclusive, equitable and timely prevention, diagnosis, treatment, care and awareness-raising related to tuberculosis, and address its economic and social determinants.`,
    card2: `Strengthen comprehensive care for all people with tuberculosis, paying particular attention to people in vulnerable situations or are vulnerable to tuberculosis, including women during pregnancy, lactation, and post-partum period, children and adolescents, people living with HIV, persons with disabilities, including those with life-long disabilities due to tuberculosis, Indigenous Peoples, health-care workers, older persons, migrants, refugees, internally displaced people, people living in situations of complex emergencies, stateless persons, people in prison and other closed settings, people living in impoverished areas, people affected by extreme poverty, miners and others exposed to silica, undernourished people, ethnic minorities, people and communities at risk of exposure to bovine tuberculosis.`,
    card3: `Recognize the enormous economic and social impacts and burden of tuberculosis for people affected by the disease, their households, in particular, for migrants and hosting countries and in this respect highlights the need to provide support as well as technical and financial assistance, to host and transit countries for strengthening local and national infrastructures and health systems for effective tuberculosis prevention, treatment and care, with a view to reducing the burden on health systems.`,
    card4: `Intensify national efforts to create enabling legal and social policy frameworks to combat inequalities, in order to eliminate all forms of tuberculosis related stigma, discrimination, inequality and other barriers, including those negatively impacting human rights, and to adopt equitable, inclusive and gender-responsive approaches, as appropriate, to address barriers to tuberculosis services that reflect the different ways men and women can be affected by tuberculosis and achieve a more effective response and greater results, so that no one is left behind in the fight against tuberculosis.`,
    card5: `Safeguard tuberculosis services as essential health services during humanitarian and health emergencies and in conflict settings, as these people may face heightened tuberculosis infection, risk of treatment interruption and limited access to quality health-care services, nutritious food and information that is language and culture sensitive.`,
  },
  30: {
    card1description: `Composants clés de CRG.`,
    selectcountry: "Sélectionner un pays",
    dropdown1title: "Évaluations au niveau des pays",
    dropdown2title: "Plan d’action au niveau national",
    card1: `Protéger et promouvoir le droit de jouir du meilleur état de santé physique et mentale possible, ainsi que le droit de bénéficier du progrès scientifique et de son application pour progresser vers l’accès universel à des services de prévention, de diagnostic, de traitement, de soins et de sensibilisation de qualité, abordables, inclusifs, équitables et opportuns en matière de tuberculose, et s’attaquer à ses déterminants économiques et sociaux.`,
    card2: `Renforcer la prise en charge intégrale de toutes les personnes atteintes de tuberculose, en accordant une attention particulière aux personnes en situation de vulnérabilité ou vulnérables à la tuberculose, y compris les femmes pendant la grossesse, l’allaitement et la période post-partum, les enfants et les adolescents, les personnes vivant avec le VIH, les personnes handicapées, y compris celles souffrant d’un handicap à vie dû à la tuberculose, les peuples autochtones, les agents de santé, les personnes âgées, les migrants, les réfugiés, les personnes déplacées à l’intérieur de leur propre pays,  les personnes vivant dans des situations d’urgence complexes, les apatrides, les personnes incarcérées et autres milieux fermés, les personnes vivant dans des zones pauvres, les personnes touchées par l’extrême pauvreté, les mineurs et autres personnes exposées à la silice, les personnes sous-alimentées, les minorités ethniques, les personnes et les communautés exposées à la tuberculose bovine.`,
    card3: `Reconnaissent l’énorme impact économique et social de la tuberculose et le fardeau que représente la tuberculose pour les personnes touchées par la maladie, leurs ménages, en particulier pour les migrants et les pays d’accueil et, à cet égard, souligne la nécessité de fournir un appui ainsi qu’une assistance technique et financière aux pays d’accueil et de transit pour renforcer les infrastructures locales et nationales et les systèmes de santé en vue d’une prévention efficace de la tuberculose,  le traitement et les soins, en vue de réduire la charge pesant sur les systèmes de santé.`,
    card4: `Intensifier les efforts nationaux visant à créer des cadres politiques juridiques et sociaux favorables à la lutte contre les inégalités, afin d’éliminer toutes les formes de stigmatisation, de discrimination, d’inégalité et d’autres obstacles liés à la tuberculose, y compris ceux qui ont une incidence négative sur les droits de l’homme, et d’adopter des approches équitables, inclusives et tenant compte de l’égalité des sexes, le cas échéant, pour éliminer les obstacles aux services de lutte contre la tuberculose qui tiennent compte des différentes façons dont les hommes et les femmes peuvent être touchés par la tuberculose et parvenir à une plus grande une réponse efficace et de meilleurs résultats, afin que personne ne soit laissé pour compte dans la lutte contre la tuberculose.`,
    card5: `Préserver les services de lutte contre la tuberculose en tant que services de santé essentiels en cas d’urgence humanitaire et sanitaire et dans les situations de conflit, car ces personnes peuvent être confrontées à une infection tuberculeuse accrue, à un risque d’interruption du traitement et à un accès limité à des services de soins de santé de qualité, à des aliments nutritifs et à des informations tenant compte de la langue et de la culture.`,
  },
};

export default crgtranslation;
