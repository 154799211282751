import { React, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import locationIcon from "../../assets/images/communitypageIcons/placeholder.svg";
import roadMapIcon from "../../assets/images/communitypageIcons/roadmap.svg";
import imgUrl from "../../assets/images/imgUrl";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import crgtranslation from "../../config/crgtranslation";

function CRGCountrySelect() {
  // select country component
  const navigate = useNavigate();
  const { language } = useLanguage();
  const t = (key) => crgtranslation[language][key] || key;
  const [country, setCountry] = useState(10); // Set the initial value to 10 for "Select country"
  const [countryAction, setCountryAction] = useState(10); // Set the initial value to 10 for "Select action"

  const handleCountryLevelChange = (event) => {
    setCountry(event.target.value);
    navigate(`/CRGAssessmentDashboard?id=${event.target.value}`);
  };

  const handleCountryActionChange = (event) => {
    setCountryAction(event.target.value);
    navigate(`/CRGActionPlanDashboard?id=${event.target.value}`);
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              <Grid
                container
                spacing={2}
                className="d-flex justify-content-center align-items-center"
              >
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  lg={2}
                  md={2}
                  className="pl-0px d-flex justify-content-center align-items-center"
                >
                  <img src={locationIcon} alt="location"></img>
                </Grid>
                <Grid item sm={8} xs={12} lg={8} md={8} className="">
                  <Typography className="mobile-text-center">
                    {t("dropdown1title")}
                  </Typography>
                  <div className="countryDropdown">
                    <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
                      <SearchIcon className="searchIcon"></SearchIcon>
                      <FormControl fullWidth className="countrySelectField">
                        {/* <InputLabel id="demo-simple-select-label">
                          Age
                        </InputLabel> */}
                        <Select
                          labelId="demo-countryLevel-select-label"
                          id="demo-countryLevel-select"
                          value={country}
                          label=""
                          onChange={handleCountryLevelChange}
                        >
                          <MenuItem value={10} disabled>
                            {t("selectcountry")}
                            {/* Placeholder text or label for the initial option */}
                          </MenuItem>
                          <MenuItem value={20}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.cameroonLogo}></img>
                              <span className="ml-10px countryName">
                                Cameroon
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={30}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.beninLogo}></img>
                              <span className="ml-10px countryName">Benin</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={40}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.rdcLogo}></img>
                              <span className="ml-10px countryName">RDC</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={50}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.congoLogo}></img>
                              <span className="ml-10px countryName">Congo</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={60}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.nigerLogo}></img>
                              <span className="ml-10px countryName">Niger</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={70}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.burkinaLogo}></img>
                              <span className="ml-10px countryName">
                                Burkina Faso
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={80}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.guineeLogo}></img>
                              <span className="ml-10px countryName">
                                Guinée
                              </span>
                            </div>
                          </MenuItem>{" "}
                          <MenuItem value={90}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.burundiLogo}></img>
                              <span className="ml-10px countryName">
                                Burundi
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={100}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.togoLogo}></img>
                              <span className="ml-10px countryName">Togo</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={101}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.maliLogo}></img>
                              <span className="ml-10px countryName">Mali</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={102}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.mauritanieLogo}></img>
                              <span className="ml-10px countryName">
                                Mauritanie
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={103}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.chadLogo}></img>
                              <span className="ml-10px countryName">Chad</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={104}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.senegalLogo}></img>
                              <span className="ml-10px countryName">
                                Sénégal
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={105}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.gabonLogo}></img>
                              <span className="ml-10px countryName">Gabon</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={106}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.centrafriqueLogo}></img>
                              <span className="ml-10px countryName">
                                Centrafrique
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={106}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.coteLogo}></img>
                              <span className="ml-10px countryName">
                                Côte d’Ivoire
                              </span>
                            </div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              {" "}
              <Grid
                container
                spacing={2}
                className="d-flex justify-content-center align-items-center"
              >
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  lg={2}
                  md={2}
                  className="pl-0px d-flex justify-content-center align-items-center"
                >
                  {" "}
                  <img src={roadMapIcon}></img>
                </Grid>
                <Grid item sm={8} xs={12} lg={8} md={8}>
                  <Typography className="mobile-text-center">
                    {t("dropdown2title")}
                  </Typography>
                  <div className="countryDropdown">
                    <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
                      <SearchIcon className="searchIcon"></SearchIcon>
                      <FormControl fullWidth className="countrySelectField">
                        {/* <InputLabel id="demo-simple-select-label">
                          Age
                        </InputLabel> */}
                        <Select
                          labelId="demo-countryAction-select-label"
                          id="demo-countryAction-select"
                          value={countryAction}
                          label=""
                          onChange={handleCountryActionChange}
                        >
                          <MenuItem value={10} disabled>
                            {t("selectcountry")}
                            {/* Placeholder text or label for the initial option */}
                          </MenuItem>
                          <MenuItem value={20}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.cameroonLogo}></img>
                              <span className="ml-10px countryName">
                                Cameroon
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={30}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.beninLogo}></img>
                              <span className="ml-10px countryName">Benin</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={40}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.rdcLogo}></img>
                              <span className="ml-10px countryName">RDC</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={50}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.congoLogo}></img>
                              <span className="ml-10px countryName">Congo</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={60}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.nigerLogo}></img>
                              <span className="ml-10px countryName">Niger</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={70}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.burkinaLogo}></img>
                              <span className="ml-10px countryName">
                                Burkina Faso
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={80}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.guineeLogo}></img>
                              <span className="ml-10px countryName">
                                Guinée
                              </span>
                            </div>
                          </MenuItem>{" "}
                          <MenuItem value={90}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.burundiLogo}></img>
                              <span className="ml-10px countryName">
                                Burundi
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={100}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.togoLogo}></img>
                              <span className="ml-10px countryName">Togo</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={101}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.maliLogo}></img>
                              <span className="ml-10px countryName">Mali</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={102}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.mauritanieLogo}></img>
                              <span className="ml-10px countryName">
                                Mauritanie
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={103}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.chadLogo}></img>
                              <span className="ml-10px countryName">Chad</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={104}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.senegalLogo}></img>
                              <span className="ml-10px countryName">
                                Sénégal
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={105}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.gabonLogo}></img>
                              <span className="ml-10px countryName">Gabon</span>
                            </div>
                          </MenuItem>
                          <MenuItem value={106}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.centrafriqueLogo}></img>
                              <span className="ml-10px countryName">
                                Centrafrique
                              </span>
                            </div>
                          </MenuItem>
                          <MenuItem value={106}>
                            <div className="d-flex justify-content-start align-items-center">
                              <img src={imgUrl.coteLogo}></img>
                              <span className="ml-10px countryName">
                                Côte d’Ivoire
                              </span>
                            </div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default CRGCountrySelect;
