import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import UNHLMScoreCountrySelect from "./UNHLMScoreCountrySelect";
import { Height } from "@mui/icons-material";
import { useLanguage } from "../../contexts/LanguageContext";
import unlhmscorecardtranslation from "../../config/unlhmscorecardtranslation";

function UNHLMScorecard() {
  const { language } = useLanguage();
  const t = (key) => unlhmscorecardtranslation[language][key] || key;

  return (
    <div className="commonPageCardSubSection">
      <div>
        <Card className="h-100" style={{ height: "330px" }}>
          <CardContent>
            <Typography> {t("card1description")} </Typography>
          </CardContent>
        </Card>
      </div>
      <div className="mt-10px">
        <UNHLMScoreCountrySelect></UNHLMScoreCountrySelect>
      </div>
    </div>
  );
}

export default UNHLMScorecard;
