export const assessment = {
  20: {
    20: {
      header: "Cameroon CRG Assessment Summary",
      description:
        "The CRG assessment conducted in 2020 highlighted several barriers related to TB services in Cameroon. Here is a summary of the findings:",
      content: [
        {
          key: "Results and Recommendations",
          value: [
            "In Cameroon, tuberculosis (TB) management faces multifaceted challenges across various domains, including legal frameworks, patient care, stigma, gender sensitivity, and community involvement. These challenges undermine efforts to control TB effectively and contribute to poor treatment outcomes. Key recommendations include prioritizing vulnerable groups such as prisoners and slum dwellers, implementing tailored interventions for prisoners, people living with HIV (PLHIV), TB contacts, refugees/displaced persons, and children, and integrating childhood TB into national disease management guidelines. Strengthening multisectoral collaboration, expanding TB service availability through primary healthcare integration, and eliminating catastrophic costs associated with TB are critical steps towards achieving these objectives.",
          ],
        },
        {
          key: "Key Populations",
          value: [
            "In Cameroon, TB disproportionately affects prisoners, PLHIV, TB contacts, refugees/displaced persons, and children. Limited data on TB among contacts hinders effective interventions. Priority actions include analyzing and prioritizing key populations, aligning data collection with global TB targets, and implementing tailored interventions like screening algorithms and community-based strategies with mobile chest X-ray units. Operational research is crucial for understanding TB burdens among contacts, while strengthened collaboration with ministries and human rights bodies aims to improve TB care and rights protection. These efforts are critical for achieving better TB control outcomes and moving towards TB elimination in Cameroon.",
          ],
        },
        {
          key: "TB Legal Environment",
          value: [
            "Cameroon lacks specific legislative provisions dedicated to tuberculosis, relying primarily on constitutional health rights and the National Committee for the Fight Against Tuberculosis (CNLT) under limited functionality. This gap necessitates the enactment of comprehensive TB legislation to provide a robust regulatory framework for managing and preventing TB effectively. Such legislation would ensure rights-based program interventions, reduce stigma and discrimination, and facilitate access to justice for TB patients.",
          ],
        },
        {
          key: "Human Rights Violations",
          value: [
            "Human rights violations are prevalent in Cameroon's TB response, including overcrowded prisons and unfair dismissals of TB patients without compensation. Weak enforcement of confidentiality regulations exacerbates these issues. Addressing these challenges requires implementing a 'Patients' Bill of Rights,' establishing mechanisms to monitor TB service availability and quality, and advocating for alternative sentencing options to alleviate prison overcrowding.",
          ],
        },
        {
          key: "Stigmatization",
          value: [
            "TB remains highly stigmatized in Cameroon, affecting patients at the family, community, and institutional levels. Current efforts to combat stigma are inadequate, with underfunded communication strategies under the National Tuberculosis Control Program (NTCP). To mitigate stigma effectively, the plan proposes revising NTCP guidelines and communication tools, training health and community staff on stigma reduction, and establishing TB Clubs in major treatment centers. These initiatives aim to empower patients and reduce societal discrimination against those affected by TB.",
          ],
        },
        {
          key: "Gender",
          value: [
            "The implementation of national gender guidelines in TB response remains weak, adversely impacting vulnerability, access to care, and treatment outcomes. Enhancing gender-sensitive approaches involves developing and implementing a gender policy for TB response, partnering with gender-focused organizations, and addressing the specific needs of women prisoners. Integrating gender-sensitive indicators into monitoring and evaluation frameworks and promoting women's participation in decision-making processes are crucial steps towards achieving gender equity in TB management.",
          ],
        },
        {
          key: "Community Involvement",
          value: [
            "Community participation in Cameroon's TB response primarily revolves around service delivery, with community health workers playing a pivotal role. However, communities struggle to influence policy debates and decision-making processes effectively. Enhancing community involvement requires supporting community-based organizations (CSOs), integrating CSO roles into health worker guides, and establishing networks of former TB patients. Strengthening CSO capacity in advocacy and accountability, alongside developing funding mechanisms, is essential for fostering sustainable community engagement in TB control efforts.",
          ],
        },
      ],
    },
    30: {
      header: "Cameroun – Résumé de l'évaluation",
      description:
        "L'évaluation CRG réalisée en 2020 a mis en évidence plusieurs obstacles liés aux services de lutte contre la tuberculose (TB) au Cameroun. Voici un résumé des conclusions:",
      content: [
        {
          key: "Résultats et Recommandations",
          value: [
            "Au Cameroun, la gestion de la tuberculose (TB) fait face à des défis multifacettes dans divers domaines, notamment les cadres juridiques, les soins aux patients, la stigmatisation, la sensibilité au genre et l'implication communautaire. Ces défis compromettent les efforts de lutte efficace contre la TB et contribuent à de mauvais résultats de traitement. Les principales recommandations incluent la priorité aux groupes vulnérables tels que les prisonniers et les habitants des bidonvilles, la mise en œuvre d'interventions adaptées pour les prisonniers, les personnes vivant avec le VIH (PLHIV), les contacts de TB, les réfugiés/personnes déplacées et les enfants, ainsi que l'intégration de la TB pédiatrique dans les directives nationales de gestion des maladies. Le renforcement de la collaboration multisectorielle, l'élargissement de la disponibilité des services de TB par l'intégration des soins de santé primaires et l'élimination des coûts catastrophiques associés à la TB sont des étapes cruciales pour atteindre ces objectifs.",
          ],
        },
        {
          key: "Populations Clés",
          value: [
            "Au Cameroun, la TB affecte de manière disproportionnée les prisonniers, les PLHIV, les contacts de TB, les réfugiés/personnes déplacées et les enfants. Les données limitées sur la TB parmi les contacts entravent les interventions efficaces. Les actions prioritaires incluent l'analyse et la priorisation des populations clés, l'alignement de la collecte des données avec les objectifs mondiaux de lutte contre la TB et la mise en œuvre d'interventions adaptées telles que des algorithmes de dépistage et des stratégies communautaires avec des unités mobiles de radiographie pulmonaire. La recherche opérationnelle est cruciale pour comprendre les charges de TB parmi les contacts, tandis que le renforcement de la collaboration avec les ministères et les organismes de défense des droits humains vise à améliorer les soins contre la TB et la protection des droits. Ces efforts sont essentiels pour obtenir de meilleurs résultats de contrôle de la TB et progresser vers l'élimination de la TB au Cameroun.",
          ],
        },
        {
          key: "Environnement Juridique de la TB",
          value: [
            "Le Cameroun manque de dispositions législatives spécifiques dédiées à la tuberculose, s'appuyant principalement sur les droits constitutionnels à la santé et le Comité National de Lutte contre la Tuberculose (CNLT) sous une fonctionnalité limitée. Cette lacune nécessite la promulgation d'une législation complète sur la TB pour fournir un cadre réglementaire solide pour la gestion et la prévention efficaces de la TB. Une telle législation garantirait des interventions programmatiques fondées sur les droits, réduirait la stigmatisation et la discrimination, et faciliterait l'accès à la justice pour les patients atteints de TB.",
          ],
        },
        {
          key: "Violations des Droits Humains",
          value: [
            `Les violations des droits humains sont fréquentes dans la réponse à la TB au Cameroun, y compris la surpopulation carcérale et les licenciements injustes des patients atteints de TB sans compensation. La faible application des règlements de confidentialité exacerbe ces problèmes. Pour relever ces défis, il est nécessaire de mettre en œuvre une "Charte des Droits des Patients", d'établir des mécanismes pour surveiller la disponibilité et la qualité des services de TB, et de plaider pour des options de condamnation alternatives pour alléger la surpopulation carcérale.`,
          ],
        },
        {
          key: "Stigmatisation",
          value: [
            "La TB reste fortement stigmatisée au Cameroun, affectant les patients au niveau familial, communautaire et institutionnel. Les efforts actuels pour lutter contre la stigmatisation sont insuffisants, avec des stratégies de communication sous-financées sous le Programme National de Lutte contre la Tuberculose (PNT). Pour atténuer efficacement la stigmatisation, le plan propose de réviser les directives et les outils de communication du PNT, de former le personnel de santé et communautaire à la réduction de la stigmatisation et d'établir des Clubs de TB dans les principaux centres de traitement. Ces initiatives visent à autonomiser les patients et à réduire la discrimination sociétale à l'égard des personnes atteintes de TB.",
          ],
        },
        {
          key: "Genre",
          value: [
            "La mise en œuvre des directives nationales sur le genre dans la réponse à la TB reste faible, affectant négativement la vulnérabilité, l'accès aux soins et les résultats du traitement. L'amélioration des approches sensibles au genre implique de développer et de mettre en œuvre une politique de genre pour la réponse à la TB, de s'associer avec des organisations axées sur le genre et de répondre aux besoins spécifiques des femmes prisonnières. L'intégration des indicateurs sensibles au genre dans les cadres de suivi et d'évaluation et la promotion de la participation des femmes aux processus décisionnels sont des étapes cruciales pour parvenir à l'équité de genre dans la gestion de la TB.",
          ],
        },
        {
          key: "Implication Communautaire",
          value: [
            "La participation communautaire dans la réponse à la TB au Cameroun repose principalement sur la prestation de services, les agents de santé communautaires jouant un rôle crucial. Cependant, les communautés peinent à influencer efficacement les débats politiques et les processus décisionnels. Renforcer l'implication communautaire nécessite de soutenir les organisations communautaires (CSOs), d'intégrer les rôles des CSOs dans les guides des travailleurs de la santé et d'établir des réseaux d'anciens patients de la TB. Le renforcement de la capacité de plaidoyer et de responsabilité des CSOs, ainsi que le développement de mécanismes de financement, est essentiel pour favoriser un engagement communautaire durable dans les efforts de lutte contre la TB.",
          ],
        },
      ],
    },
  },
  30: {
    20: {
      header: "Benin Assessment Summary",
      description:
        "Community Assessment: Tuberculosis Management in Benin 2020",
      content: [
        {
          key: "Commitment to Care Barriers",
          value: [
            "Nutritional Support: Provision of nutritional support for TB patients is insufficient, impacting their overall health and ability to adhere to treatment regimens. Malnutrition exacerbates treatment outcomes and patient recovery.",
            "Comorbidity Management: Effective management of comorbidities, such as anemia, is inadequate within the TB care framework. This deficiency complicates treatment and recovery for TB patients, requiring integrated healthcare approaches.",
            "Community Involvement: There is a notable lack of engagement from local authorities and non-governmental organizations (NGOs) in addressing gender-related and human rights issues affecting TB patients. Community support systems are underdeveloped, hindering comprehensive care delivery.",
            "Stakeholder Engagement: While the Association of Cured Tuberculosis Patients (ASSAP-TB) supports national TB policies through awareness campaigns and patient advocacy, broader community involvement and NGO participation in TB control efforts are limited.",
          ],
        },
        {
          key: "Accountability and Resource Access",
          value: [
            "Healthcare Worker Commitment: Despite resource constraints, healthcare workers demonstrate strong dedication to patient care. Many go beyond their duty, using personal resources to support patients financially and emotionally.",
            "Resource Accessibility: Although CDTs are widely distributed, accommodation conditions vary, and there are periodic shortages of essential medications and food supplies for TB patients. These challenges impact treatment adherence and patient outcomes.",
            "Financial Constraints: A significant number of TB patients face financial hardships, which hinder their access to comprehensive care. Healthcare workers often contribute personal funds to support patients, highlighting gaps in public health funding and social support systems.",
          ],
        },
        {
          key: "Rights and Legal Framework",
          value: [
            "Freedom and Quarantine: There are no specific laws restricting the freedoms of TB patients in Benin. However, mandatory TB screening for certain professions and occasional use of quarantine in schools and workplaces underscore gaps in legal protections and patient rights awareness.",
            "Stigma and Discrimination: TB patients encounter social stigma, leading to isolation and discrimination within their communities. Stigmatization contributes to patients' self-isolation and impacts their mental health and social integration.",
          ],
        },
        {
          key: "Gender and Vulnerability",
          value: [
            "Gender Disparities: Healthcare professionals maintain gender-neutral treatment policies, but socio-cultural norms often require female patients to obtain spousal consent for medical treatment. This requirement limits women's autonomy in healthcare decision-making and can delay timely treatment.",
            "Economic Vulnerability: TB exacerbates economic vulnerability among patients, particularly those in the informal sector. Loss of employment due to illness and stigma further impoverishes affected individuals and their families, creating barriers to sustainable recovery.",
          ],
        },
        {
          key: "Conclusion",
          value: [
            "In Benin, TB management faces significant challenges that disproportionately affect vulnerable populations. These challenges include stigma, discrimination, inadequate nutritional support, and limited community engagement in TB control efforts. Addressing these issues requires strengthening community involvement, enhancing resource accessibility, promoting awareness of patient rights, and advocating for comprehensive legal protections. By addressing these challenges, Benin can improve TB care delivery, mitigate socio-economic impacts, and enhance the well-being of affected populations.",
          ],
        },
      ],
    },
    30: {
      header: "Évaluation du Bénin",
      description:
        "Évaluation Communautaire : Gestion de la Tuberculose au Bénin 2020",
      content: [
        {
          key: "Obstacles à l'Engagement des Soins",
          value: [
            "Soutien Nutritionnel : La provision de soutien nutritionnel pour les patients atteints de tuberculose est insuffisante, affectant leur santé globale et leur capacité à suivre les régimes de traitement. La malnutrition aggrave les résultats du traitement et la récupération des patients.",
            "Gestion des Comorbidités : La gestion efficace des comorbidités, comme l'anémie, est inadéquate dans le cadre des soins de la tuberculose. Cette lacune complique le traitement et la récupération des patients, nécessitant des approches de soins de santé intégrés.",
            "Implication Communautaire : Il y a un manque notable d'engagement des autorités locales et des organisations non gouvernementales (ONG) dans la gestion des questions de genre et des droits humains affectant les patients atteints de tuberculose. Les systèmes de soutien communautaire sont sous-développés, entravant la prestation de soins complets.",
            "Engagement des Parties Prenantes : Bien que l'Association des Guéris de la Tuberculose (ASSAP-TB) soutienne les politiques nationales contre la tuberculose par des campagnes de sensibilisation et de plaidoyer, l'implication plus large de la communauté et la participation des ONG dans les efforts de lutte contre la tuberculose sont limitées.",
          ],
        },
        {
          key: "Responsabilité et Accès aux Ressources",
          value: [
            "Engagement des Travailleurs de la Santé : Malgré les contraintes de ressources, les travailleurs de la santé démontrent un fort dévouement aux soins des patients. Beaucoup vont au-delà de leur devoir, utilisant des ressources personnelles pour soutenir financièrement et émotionnellement les patients.",
            "Accessibilité des Ressources : Bien que les CDTs soient largement répartis, les conditions d'hébergement varient et il y a des pénuries périodiques de médicaments essentiels et de provisions alimentaires pour les patients atteints de tuberculose. Ces défis affectent l'adhésion au traitement et les résultats des patients.",
            "Contraintes Financières : Un nombre important de patients atteints de tuberculose font face à des difficultés financières, ce qui entrave leur accès à des soins complets. Les travailleurs de la santé contribuent souvent personnellement pour soutenir les patients, mettant en évidence les lacunes dans le financement de la santé publique et les systèmes de soutien social.",
          ],
        },
        {
          key: "Droits et Cadre Juridique",
          value: [
            "Liberté et Quarantaine : Il n'existe pas de lois spécifiques restreignant les libertés des patients atteints de tuberculose au Bénin. Cependant, le dépistage obligatoire de la tuberculose pour certaines professions et l'utilisation occasionnelle de la quarantaine dans les écoles et les lieux de travail soulignent des lacunes dans les protections juridiques et la sensibilisation aux droits des patients.",
            "Stigmatisation et Discrimination : Les patients atteints de tuberculose rencontrent une stigmatisation sociale, conduisant à l'isolement et à la discrimination au sein de leurs communautés. La stigmatisation contribue à l'auto-isolement des patients et impacte leur santé mentale et leur intégration sociale.",
          ],
        },
        {
          key: "Genre et Vulnérabilité",
          value: [
            "Disparités de Genre : Les professionnels de la santé maintiennent des politiques de traitement neutres en matière de genre, mais les normes socioculturelles exigent souvent des patientes qu'elles obtiennent le consentement de leur conjoint pour un traitement médical. Cette exigence limite l'autonomie des femmes dans les décisions de santé et peut retarder un traitement opportun.",
            "Vulnérabilité Économique : La tuberculose exacerbe la vulnérabilité économique des patients, en particulier ceux du secteur informel. La perte d'emploi due à la maladie et à la stigmatisation appauvrit davantage les individus et leurs familles, créant des obstacles à une récupération durable.",
          ],
        },
        {
          key: "Conclusion",
          value: [
            "Au Bénin, la gestion de la tuberculose fait face à des défis significatifs qui affectent de manière disproportionnée les populations vulnérables. Ces défis incluent la stigmatisation, la discrimination, le soutien nutritionnel insuffisant et le manque d'engagement communautaire dans les efforts de lutte contre la tuberculose. Aborder ces questions nécessite de renforcer l'implication communautaire, d'améliorer l'accessibilité des ressources, de promouvoir la sensibilisation aux droits des patients et de plaider pour des protections juridiques complètes. En abordant ces défis, le Bénin peut améliorer la prestation des soins contre la tuberculose, atténuer les impacts socio-économiques et améliorer le bien-être des populations affectées.",
          ],
        },
      ],
    },
  },
  40: {
    20: {
      header: "RDC CRG Assessment Summary",
      description: "",
      content: [
        {
          key: "Barriers to TB Care in the DRC:",
          value: [
            "Stigma: Stigma associated with TB is pervasive in the DRC, affecting both patients and healthcare settings. Self-stigma among patients, community discrimination, and prejudiced attitudes among healthcare providers contribute to delays in diagnosis, treatment non-adherence, and social isolation.",
          ],
        },
        {
          key: "Access to Services",
          value: [
            "Financial Barriers: Fees for TB consultations, diagnostic tests (such as radiology and sputum examinations), and treatment pose significant challenges for individuals with limited financial means.",
            "Geographical Accessibility: Limited availability of TB services in remote or rural areas further exacerbates barriers to accessing timely diagnosis and treatment.",
            "Health System Challenges: Inadequate supply of TB drugs, frequent stockouts, and insufficient integration of TB/HIV services in some health facilities impede effective TB management.",
          ],
        },
        {
          key: "Human Rights Issues:",
          value: [
            "Legal Protections: The absence of comprehensive legal frameworks protecting TB patients' rights, including confidentiality and non-discrimination, perpetuates stigma and undermines patient trust in the healthcare system.",
            "Quality of Care: Variability in healthcare quality, including suboptimal implementation of treatment strategies like Directly Observed Therapy, Short-course (DOTS), impacts treatment outcomes and patient satisfaction.",
            "Data Gaps: Insufficient data collection and monitoring on TB-related barriers, including stigma, hinder the development of targeted interventions and policy improvements.",
          ],
        },
        {
          key: "Comprehensive Recommendations for the DRC:",
          value: [],
        },
        {
          key: "Policy and Integration:",
          value: [
            "Revise national TB policies to integrate gender-sensitive, human rights-based approaches into TB care guidelines. Ensure widespread dissemination and effective implementation across all healthcare facilities.",
            "Strengthen coordination between TB and HIV/AIDS programs to enhance service delivery and reduce stigma through integrated care models.",
          ],
        },
        {
          key: "Financial and Resource Support:",
          value: [
            "Eliminate user fees for TB consultations, diagnostics, and treatment to improve equity in access to TB services.",
            "Secure sustainable funding and enhance supply chain management to ensure continuous availability of TB drugs and diagnostic tools in all health facilities.",
          ],
        },
        {
          key: "Community Engagement and Education:",
          value: [
            "Conduct targeted awareness campaigns to educate communities about TB transmission, symptoms, and treatment to reduce stigma and promote early detection.",
            "Empower community health workers, traditional leaders, and local influencers to advocate for TB patients' rights and provide psychosocial support.",
          ],
        },
        {
          key: "Healthcare System Strengthening:",
          value: [
            "Provide comprehensive training for healthcare providers on patient-centered care, stigma reduction, and confidentiality in TB treatment settings.",
            "Implement quality improvement initiatives to standardize TB care practices, including robust referral systems and consistent patient monitoring.",
          ],
        },
        {
          key: "Legal and Rights-Based Approaches:",
          value: [
            "Advocate for legislative reforms to protect TB patients from discrimination and ensure their rights to confidential TB care.",
            "Establish mechanisms for legal aid and support for TB patients facing stigma or rights violations, including community-based mediation and advocacy.",
          ],
        },
        {
          key: "Monitoring and Evaluation:",
          value: [
            "Develop and implement robust data collection systems to monitor TB-related barriers, including stigma, across diverse demographic groups and geographical regions.",
            "Utilize community-based monitoring to assess the effectiveness of stigma reduction interventions and inform evidence-based policy adjustments.",
          ],
        },
        {
          key: "By addressing stigma along with other barriers to TB care in the DRC, these integrated efforts aim to create a more inclusive and effective healthcare environment that upholds the rights and dignity of all individuals affected by TB. These initiatives are crucial steps toward improving access to TB services and achieving better health outcomes for TB patients in the country.",
          value: [],
        },
      ],
    },
    30: {
      header: "Évaluation CRG pour la RDC",
      description: "",
      content: [
        {
          key: "Obstacles aux Soins de la Tuberculose en RDC:",
          value: [
            "Stigmatisation: La stigmatisation associée à la TB est omniprésente en RDC, affectant à la fois les patients et les milieux de soins de santé. L'auto-stigmatisation des patients, la discrimination communautaire et les attitudes préjudiciables des prestataires de soins de santé contribuent aux retards de diagnostic, à la non-observance du traitement et à l'isolement social.",
          ],
        },
        {
          key: "Accès aux Services",
          value: [
            "Obstacles Financiers : Les frais pour les consultations TB, les tests diagnostiques (tels que la radiologie et les examens des crachats) et le traitement posent des défis importants pour les personnes ayant des moyens financiers limités.",
            "Accessibilité Géographique : La disponibilité limitée des services de TB dans les zones éloignées ou rurales exacerbe encore les obstacles à l'accès à un diagnostic et un traitement en temps opportun.",
            "Défis du Système de Santé : Un approvisionnement inadéquat en médicaments contre la TB, des ruptures de stock fréquentes et une intégration insuffisante des services TB/VIH dans certaines installations de santé entravent une gestion efficace de la TB.",
          ],
        },
        {
          key: "Problèmes de Droits Humains:",
          value: [
            "Protections Juridiques : L'absence de cadres juridiques complets protégeant les droits des patients TB, y compris la confidentialité et la non-discrimination, perpétue la stigmatisation et sape la confiance des patients dans le système de santé.",
            "Qualité des Soins : La variabilité de la qualité des soins de santé, y compris une mise en œuvre sous-optimale des stratégies de traitement comme la thérapie directement observée à court terme (DOTS), impacte les résultats du traitement et la satisfaction des patients.",
            "Lacunes dans les Données : Une collecte de données et un suivi insuffisants sur les obstacles liés à la TB, y compris la stigmatisation, entravent le développement d'interventions ciblées et les améliorations politiques.",
          ],
        },
        {
          key: "Recommandations Complètes pour la RDC:",
          value: [],
        },
        {
          key: "Politique et Intégration:",
          value: [
            "Réviser les politiques nationales de lutte contre la TB pour intégrer des approches sensibles au genre et basées sur les droits humains dans les lignes directrices de soins de la TB. Assurer une large diffusion et une mise en œuvre efficace dans tous les établissements de santé.",
            "Renforcer la coordination entre les programmes de TB et de VIH/SIDA pour améliorer la prestation des services et réduire la stigmatisation grâce à des modèles de soins intégrés.",
          ],
        },
        {
          key: "Soutien Financier et en Ressources:",
          value: [
            "Éliminer les frais pour les consultations, les diagnostics et le traitement de la TB afin d'améliorer l'équité dans l'accès aux services de TB.",
            "Sécuriser un financement durable et améliorer la gestion de la chaîne d'approvisionnement pour assurer la disponibilité continue des médicaments contre la TB et des outils diagnostiques dans tous les établissements de santé.",
          ],
        },
        {
          key: "Engagement et Éducation Communautaires:",
          value: [
            "Mener des campagnes de sensibilisation ciblées pour éduquer les communautés sur la transmission, les symptômes et le traitement de la TB afin de réduire la stigmatisation et promouvoir la détection précoce.",
            "Émanciper les agents de santé communautaire, les leaders traditionnels et les influenceurs locaux pour défendre les droits des patients TB et fournir un soutien psychosocial.",
          ],
        },
        {
          key: "Renforcement du Système de Santé:",
          value: [
            "Fournir une formation complète aux prestataires de soins de santé sur les soins centrés sur le patient, la réduction de la stigmatisation et la confidentialité dans les environnements de traitement de la TB.",
            "Mettre en œuvre des initiatives d'amélioration de la qualité pour standardiser les pratiques de soins TB, y compris des systèmes de référence robustes et un suivi cohérent des patients.",
          ],
        },
        {
          key: "Approches Juridiques et Basées sur les Droits:",
          value: [
            "Plaider pour des réformes législatives pour protéger les patients TB de la discrimination et assurer leurs droits à des soins TB confidentiels.",
            "Établir des mécanismes d'aide juridique et de soutien pour les patients TB faisant face à la stigmatisation ou aux violations des droits, y compris la médiation communautaire et le plaidoyer.",
          ],
        },
        {
          key: "Suivi et Évaluation:",
          value: [
            "Développer et mettre en œuvre des systèmes robustes de collecte de données pour surveiller les obstacles liés à la TB, y compris la stigmatisation, parmi divers groupes démographiques et régions géographiques.",
            "Utiliser le suivi communautaire pour évaluer l'efficacité des interventions de réduction de la stigmatisation et informer les ajustements politiques basés sur les preuves.",
          ],
        },
        {
          key: "En s'attaquant à la stigmatisation ainsi qu'aux autres obstacles aux soins de la TB en RDC, ces efforts intégrés visent à créer un environnement de soins de santé plus inclusif et efficace qui respecte les droits et la dignité de toutes les personnes touchées par la TB. Ces initiatives sont des étapes cruciales pour améliorer l'accès aux services de TB et obtenir de meilleurs résultats de santé pour les patients TB dans le pays.",
          value: [],
        },
      ],
    },
  },
  60: {
    20: {
      header: "Niger CRG Assessment Summary",
      description: "",
      content: [
        {
          key: "TB Legal Environment",
          value: [
            "In Niger, the constitutional rights to health are upheld, and the National Tuberculosis Program (PNLT/MR) is established under a decree. However, there is a notable absence of specific legal statutes addressing the management and prevention of tuberculosis. This legal gap highlights the need for comprehensive tuberculosis legislation tailored to Niger's context. Such legislation would provide a structured framework to ensure effective tuberculosis care and protection of patients' rights.",
          ],
        },
        {
          key: "Weaknesses in TB Patient Care",
          value: [
            "Several challenges undermine the quality of tuberculosis patient care in Niger. One significant issue is the delayed utilization of healthcare facilities, particularly among women. Additionally, some patients fail to adhere to scheduled appointments, which impacts treatment continuity and effectiveness. There is also a critical shortfall in nutritional support for patients undergoing tuberculosis treatment. Moreover, inadequate coordination across various sectors involved in tuberculosis management further complicates efforts to deliver comprehensive care",
            "To address these challenges, it is imperative to bolster efforts to enhance healthcare access and adherence. This includes intensifying awareness campaigns to encourage timely visits to health centers and promoting referrals for suspected cases. Strategies to improve patient appointment adherence must be implemented, alongside initiatives to support the socio-economic reintegration of recovered patients and ensure they receive sufficient nutritional support during treatment. Furthermore, strengthening coordination mechanisms and fostering multisectoral collaboration are essential to streamline service delivery and enhance overall patient support.",
          ],
        },
        {
          key: "Stigmatization of TB Patients",
          value: [
            "Stigmatization remains a significant barrier to effective tuberculosis management in Niger. TB patients often internalize this stigma, resulting in irregular adherence to treatment regimens or even outright refusal of treatment. The impact of stigma is pervasive, affecting TB patients at the familial, community, and institutional levels, including within healthcare settings.",
            "To combat stigma and discrimination effectively, comprehensive strategies are needed. This includes developing standardized counseling tools that embed key messages for caregivers, aimed at supporting patients psychologically and reinforcing treatment adherence. Equally important is the comprehensive education of patients on their human rights, emphasizing their entitlement to non-discriminatory healthcare services. Targeted awareness campaigns should be developed to address stigma across various levels of society, including families, communities, and public institutions. Advocacy efforts should also prioritize the development and adoption of legal protections specifically designed to safeguard the rights and dignity of people living with tuberculosis.",
            "In conclusion, these recommendations aim to tackle the multifaceted challenges faced in tuberculosis management in Niger. By improving legal frameworks, enhancing patient care, and combating stigma, these efforts seek to create a more supportive environment for TB patients and ultimately improve treatment outcomes across the country.",
          ],
        },
      ],
    },
    30: {
      header: "Évaluation CRG au Niger",
      description: "",
      content: [
        {
          key: "Environnement Juridique de la TB",
          value: [
            "Au Niger, les droits constitutionnels à la santé sont respectés, et le Programme National de Lutte contre la Tuberculose (PNLT/MR) est établi par décret. Cependant, il manque des lois spécifiques traitant de la gestion et de la prévention de la tuberculose. Cette lacune juridique souligne la nécessité d'une législation complète sur la tuberculose adaptée au contexte nigérien. Une telle législation fournirait un cadre structuré pour garantir des soins efficaces contre la tuberculose et la protection des droits des patients.",
          ],
        },
        {
          key: "Faiblesses dans les Soins aux Patients Atteints de TB",
          value: [
            "Plusieurs défis compromettent la qualité des soins aux patients atteints de tuberculose au Niger. Un problème majeur est le retard dans l'utilisation des établissements de santé, notamment chez les femmes. De plus, certains patients ne respectent pas les rendez-vous prévus, ce qui affecte la continuité et l'efficacité du traitement. Il y a également une pénurie critique de soutien nutritionnel pour les patients sous traitement contre la tuberculose. Par ailleurs, une coordination inadéquate entre les différents secteurs impliqués dans la gestion de la tuberculose complique davantage les efforts pour fournir des soins complets.",
            "Pour relever ces défis, il est impératif de renforcer les efforts pour améliorer l'accès aux soins de santé et l'adhésion. Cela inclut l'intensification des campagnes de sensibilisation pour encourager les visites rapides dans les centres de santé et promouvoir les références pour les cas suspects. Des stratégies pour améliorer l'adhésion des patients aux rendez-vous doivent être mises en œuvre, ainsi que des initiatives pour soutenir la réintégration socio-économique des patients guéris et garantir qu'ils reçoivent un soutien nutritionnel suffisant pendant le traitement. En outre, le renforcement des mécanismes de coordination et la promotion de la collaboration multisectorielle sont essentiels pour rationaliser la prestation des services et améliorer le soutien global aux patients.",
          ],
        },
        {
          key: "Stigmatisation des Patients Atteints de TB",
          value: [
            "La stigmatisation reste un obstacle majeur à la gestion efficace de la tuberculose au Niger. Les patients atteints de TB internalisent souvent cette stigmatisation, ce qui entraîne une adhésion irrégulière aux régimes de traitement ou même un refus total de traitement. L'impact de la stigmatisation est omniprésent, affectant les patients TB au niveau familial, communautaire et institutionnel, y compris au sein des établissements de santé.",
            "Pour lutter efficacement contre la stigmatisation et la discrimination, des stratégies globales sont nécessaires. Cela inclut le développement d'outils de conseil standardisés qui intègrent des messages clés pour les soignants, visant à soutenir psychologiquement les patients et à renforcer l'adhésion au traitement. Il est également important d'éduquer les patients sur leurs droits humains, en mettant l'accent sur leur droit à des services de santé non discriminatoires. Des campagnes de sensibilisation ciblées doivent être développées pour aborder la stigmatisation à divers niveaux de la société, y compris les familles, les communautés et les institutions publiques. Les efforts de plaidoyer doivent également donner la priorité au développement et à l'adoption de protections juridiques spécialement conçues pour sauvegarder les droits et la dignité des personnes vivant avec la tuberculose.",
            "En conclusion, ces recommandations visent à relever les défis multifacettes rencontrés dans la gestion de la tuberculose au Niger. En améliorant les cadres juridiques, en renforçant les soins aux patients et en combattant la stigmatisation, ces efforts cherchent à créer un environnement plus favorable pour les patients TB et à améliorer les résultats des traitements à travers le pays.",
          ],
        },
      ],
    },
  },
  101: {
    20: {
      header: "Mali CRG Assessmen Summary",
      description:
        "The CRG assessment in 2023 illuminated critical barriers within Mali's TB services, vital for improving prevention, diagnosis, and treatment.",
      content: [
        {
          key: "Legal Point of View",
          value: [
            "Mali's TB-related legal framework is scant, focusing on national bodies and general medical practices. Existing laws indirectly touch on TB through human rights, health access, and non-discrimination, yet comprehension among decision-makers, caregivers, and communities is deficient. Gender-specific aspects are especially overlooked, necessitating advocacy to enhance awareness and adherence to laws safeguarding health rights, combating discrimination, and reducing stigma.",
          ],
        },
        {
          key: "Health Point of View",
          value: [
            "Progress across institutional, operational, and clinical aspects of Mali's TB program is evident through integration with health programs, reference laboratories, and advanced screening strategies. However, communication primarily centers on symptom identification, with varied public knowledge of prevention methods. Persistent drug shortages stem from restrictive supply systems and inadequate planning at health facilities. Strengthening healthcare workers' TB knowledge and conducting structured awareness campaigns are crucial. Community outreach must expand to enhance TB awareness in local settings",
          ],
        },
        {
          key: "Health Structures",
          value: [
            "TB screening rates remain disproportionately low compared to curative consultations, highlighting resource scarcity at lower health pyramid levels. Insufficient technical resources at Community Health Centers (CSComs) hinder effective TB management. Similar deficiencies at Referral Health Centers (RefScos) exacerbate challenges, compounded by inadequate integration of HIV services. Community-based screening initiatives are vital for improving detection rates. Enhanced community interventions, including psychosocial support and adherence programs, are imperative for comprehensive patient care.",
          ],
        },
        {
          key: "Nursing Staff",
          value: [
            "Healthcare personnel endure adverse socio-economic conditions, impacting job satisfaction and care quality. High workloads and inadequate facilities contribute to dissatisfaction. Many lack TB management expertise, particularly in CSRefs, necessitating enhanced training and community involvement. Empowering staff to prescribe medications and training in infection control are vital to minimize TB transmission risks.",
          ],
        },
        {
          key: "Infected and Affected Vulnerable Populations",
          value: [
            "Perceptions on service organization and communication effectiveness vary among stakeholders. Comprehensive patient support, especially psychosocial care, requires improvement. Family involvement is pivotal, but stigma persists across social, familial, and healthcare settings. Efforts are needed to organize services for HIV key populations, enhance awareness, and reduce intra-family stigma. Addressing financial hardships and empowering women in TB care are critical, alongside educating communities on healthcare rights and laws.",
          ],
        },
        {
          key: "General Recommendations",
          value: [
            "Improving technical infrastructure and augmenting human resources for TB screening and diagnosis are essential. Capacity building at CSComs and integrating community-based interventions are pivotal. Financial accessibility must extend beyond treatment to cover transport and additional costs. Operational research, emphasizing community health worker involvement, enhances program efficacy and sustainability.",
            "In conclusion, tackling these multifaceted challenges demands integrated efforts across legal, healthcare, nursing, and community domains to ensure effective TB management in Mali.",
          ],
        },
      ],
    },
    30: {
      header: "Évaluation CRG au Mali",
      description:
        "L'évaluation CRG de 2023 a mis en lumière des obstacles critiques au sein des services de lutte contre la tuberculose (TB) au Mali, essentiels pour améliorer la prévention, le diagnostic et le traitement.",
      content: [
        {
          key: "Point de Vue Juridique",
          value: [
            "Le cadre juridique du Mali lié à la TB est limité, se concentrant sur les organismes nationaux et les pratiques médicales générales. Les lois existantes touchent indirectement à la TB à travers les droits de l'homme, l'accès à la santé et la non-discrimination, mais la compréhension parmi les décideurs, les soignants et les communautés est insuffisante. Les aspects spécifiques au genre sont particulièrement négligés, nécessitant un plaidoyer pour améliorer la sensibilisation et l'adhésion aux lois protégeant les droits de santé, luttant contre la discrimination et réduisant la stigmatisation.",
          ],
        },
        {
          key: "Point de Vue Sanitaire",
          value: [
            "Les progrès dans les aspects institutionnels, opérationnels et cliniques du programme de lutte contre la TB au Mali sont évidents grâce à l'intégration avec les programmes de santé, les laboratoires de référence et les stratégies de dépistage avancées. Cependant, la communication se concentre principalement sur l'identification des symptômes, avec des connaissances publiques variées sur les méthodes de prévention. Les pénuries persistantes de médicaments résultent de systèmes d'approvisionnement restrictifs et d'une planification inadéquate dans les établissements de santé. Renforcer les connaissances des agents de santé sur la TB et mener des campagnes de sensibilisation structurées sont cruciaux. La sensibilisation communautaire doit s'étendre pour améliorer la connaissance de la TB dans les milieux locaux",
          ],
        },
        {
          key: "Structures de Santé",
          value: [
            "Les taux de dépistage de la TB restent disproportionnellement bas par rapport aux consultations curatives, mettant en évidence la rareté des ressources aux niveaux inférieurs de la pyramide sanitaire. Des ressources techniques insuffisantes dans les Centres de Santé Communautaires (CSComs) entravent une gestion efficace de la TB. Des déficiences similaires dans les Centres de Santé de Référence (RefScos) aggravent les défis, aggravés par une intégration inadéquate des services VIH. Les initiatives de dépistage communautaire sont vitales pour améliorer les taux de détection. Des interventions communautaires renforcées, y compris le soutien psychosocial et les programmes d'adhérence, sont impératives pour des soins complets aux patients.",
          ],
        },
        {
          key: "Personnel Infirmier",
          value: [
            "Le personnel de santé endure des conditions socio-économiques défavorables, impactant la satisfaction au travail et la qualité des soins. Les charges de travail élevées et les installations inadéquates contribuent à l'insatisfaction. Beaucoup manquent d'expertise en gestion de la TB, particulièrement dans les CSRefs, nécessitant une formation renforcée et une implication communautaire. Autonomiser le personnel pour prescrire des médicaments et former au contrôle des infections est vital pour minimiser les risques de transmission de la TB.",
          ],
        },
        {
          key: "Populations Vulnérables Infectées et Affectées",
          value: [
            "Les perceptions sur l'organisation des services et l'efficacité de la communication varient parmi les parties prenantes. Le soutien complet aux patients, en particulier les soins psychosociaux, nécessite une amélioration. L'implication familiale est essentielle, mais la stigmatisation persiste dans les milieux sociaux, familiaux et de soins de santé. Des efforts sont nécessaires pour organiser les services pour les populations clés du VIH, améliorer la sensibilisation et réduire la stigmatisation intra-familiale. Aborder les difficultés financières et autonomiser les femmes dans les soins contre la TB sont critiques, ainsi que sensibiliser les communautés aux droits et lois en matière de santé.",
          ],
        },
        {
          key: "Recommandations Générales",
          value: [
            "Améliorer l'infrastructure technique et augmenter les ressources humaines pour le dépistage et le diagnostic de la TB sont essentiels. Le renforcement des capacités des CSComs et l'intégration des interventions communautaires sont cruciaux. L'accessibilité financière doit s'étendre au-delà du traitement pour couvrir les coûts de transport et autres frais supplémentaires. La recherche opérationnelle, mettant l'accent sur l'implication des agents de santé communautaires, améliore l'efficacité et la durabilité du programme.",
            "En conclusion, relever ces défis multifacettes demande des efforts intégrés dans les domaines juridique, sanitaire, infirmier et communautaire pour assurer une gestion efficace de la TB au Mali.",
          ],
        },
      ],
    },
  },
  103: {
    20: {
      header: "Tchad CRG Assessment Summary",
      description: `The CRG assessment conducted in 2022 highlighted several barriers related to TB services. Here is a summary of the findings.
      In Chad, accessing tuberculosis (TB) services is impeded by a complex interplay of gender disparities, stigma, socioeconomic challenges, and logistical barriers.`,
      content: [
        {
          key: "Gender Disparities and Stigma:",
          value: [
            "Men are notably more affected by TB compared to women, with a widening gender gap observed over recent years. Women face heightened stigma, which significantly hampers their ability to access TB diagnosis and treatment services. Children are often underreported in TB cases and have limited access to preventive therapies like INH prophylaxis. Stigma and familial rejection further discourage individuals from seeking necessary TB diagnostic and support services.",
          ],
        },
        {
          key: "Socioeconomic and Nutritional Challenges:",
          value: [
            "Socioeconomic instability and malnutrition are pervasive risk factors among TB patients in Chad, exacerbating difficulties in accessing and adhering to treatment.",
          ],
        },
        {
          key: "Co-infection and Double Stigma:",
          value: [
            "The co-infection of TB with HIV intensifies stigma and mortality rates, creating a dual burden of discrimination that deters individuals from seeking essential healthcare services.",
          ],
        },
        {
          key: "Access and Decentralization Issues:",
          value: [
            "Geographical challenges also impede access, particularly for nomadic populations, refugees, and internally displaced persons who struggle to reach centralized TB Diagnostic and Treatment Centers (CDTs) due to limited decentralization of services. This geographical barrier results in delayed diagnosis and treatment, contributing to ongoing transmission within communities.",
          ],
        },
        {
          key: "Human Rights and Programmatic Limitations:",
          value: [
            "Despite efforts outlined in Chad's 2021-2025 strategic plan to promote human rights and gender equity within TB care, interventions remain fragmented and underfunded. Ad hoc approaches to addressing TB-related human rights abuses and the limited integration of TB services into broader health programs represent missed opportunities for comprehensive care.",
            "In conclusion, addressing these multifaceted barriers—gender disparities, stigma, socioeconomic challenges, co-infection issues, access barriers, and human rights violations—is critical to improving TB service delivery and outcomes in Chad. A concerted effort is needed to strengthen programmatic interventions, enhance community engagement, and secure sustained funding to effectively combat TB in all its dimensions.",
          ],
        },
      ],
    },
    30: {
      header: "Évaluation CRG pour le Tchad",
      description: `L'évaluation CRG réalisée en 2022 a mis en évidence plusieurs obstacles liés aux services de lutte contre la tuberculose (TB). Voici un résumé des conclusions: Au Tchad, l'accès aux services de lutte contre la tuberculose (TB) est entravé par une interaction complexe de disparités de genre, de stigmatisation, de défis socio-économiques et de barrières logistiques.`,
      content: [
        {
          key: "Disparités de Genre et Stigmatisation:",
          value: [
            "Les hommes sont nettement plus touchés par la TB que les femmes, avec un écart de genre qui s'est creusé ces dernières années. Les femmes font face à une stigmatisation accrue, ce qui entrave considérablement leur capacité à accéder aux services de diagnostic et de traitement de la TB. Les enfants sont souvent sous-déclarés dans les cas de TB et ont un accès limité aux thérapies préventives comme la prophylaxie INH. La stigmatisation et le rejet familial découragent également les individus de rechercher les services de diagnostic et de soutien nécessaires pour la TB.",
          ],
        },
        {
          key: "Défis Socio-Économiques et Nutritionnels:",
          value: [
            "L'instabilité socio-économique et la malnutrition sont des facteurs de risque omniprésents chez les patients TB au Tchad, exacerbant les difficultés d'accès et d'adhésion au traitement.",
          ],
        },
        {
          key: "Co-Infection et Double Stigmatisation:",
          value: [
            "La co-infection TB-VIH intensifie la stigmatisation et les taux de mortalité, créant un double fardeau de discrimination qui dissuade les individus de chercher les services de santé essentiels.",
          ],
        },
        {
          key: "Problèmes d'Accès et de Décentralisation:",
          value: [
            "Les défis géographiques entravent également l'accès, en particulier pour les populations nomades, les réfugiés et les personnes déplacées à l'intérieur du pays, qui ont du mal à atteindre les Centres de Diagnostic et de Traitement (CDT) centralisés en raison de la décentralisation limitée des services. Cette barrière géographique entraîne des retards de diagnostic et de traitement, contribuant à la transmission continue au sein des communautés.",
          ],
        },
        {
          key: "Droits Humains et Limites Programmatiques:",
          value: [
            "Malgré les efforts décrits dans le plan stratégique 2021-2025 du Tchad pour promouvoir les droits humains et l'équité de genre dans les soins TB, les interventions restent fragmentées et sous-financées. Les approches ad hoc pour traiter les abus des droits humains liés à la TB et l'intégration limitée des services de TB dans les programmes de santé plus larges représentent des occasions manquées pour des soins complets.",
            "En conclusion, il est essentiel de s'attaquer à ces obstacles multifactoriels—disparités de genre, stigmatisation, défis socio-économiques, problèmes de co-infection, barrières d'accès et violations des droits humains—pour améliorer la prestation des services de TB et les résultats au Tchad. Un effort concerté est nécessaire pour renforcer les interventions programmatiques, améliorer l'engagement communautaire et garantir un financement soutenu pour lutter efficacement contre la TB dans toutes ses dimensions.",
          ],
        },
      ],
    },
  },
  104: {
    20: {
      header: "Senegal Assessment Summary",
      description: `The CRG assessment conducted in 2020 highlighted several barriers related to TB services. Here is a summary of the findings.`,
      content: [
        {
          key: "Accessibility Barriers:",
          value: [
            "Financial Constraints: 86.6% of CDTs cited lack of financial resources as a major barrier for TB patients.",
            "Geographical Challenges: 40% mentioned remoteness of healthcare facilities as a significant barrier to accessing services.",
            "Treatment Dropout Rates: 26.67% of TB patients discontinued treatment within 2 months due to inadequate understanding of TB and human rights.",
          ],
        },
        {
          key: "Quality of Services:",
          value: [
            "Patient Satisfaction: Despite challenges, TB patients expressed satisfaction with the quality of services provided during their appointments, which influenced their adherence to treatment.",
          ],
        },
        {
          key: "Stigma and Discrimination:",
          value: [
            "Perceptions: TB patients and vulnerable populations reported varying degrees of stigma at community, workplace, and healthcare settings.",
            "Recommendations: Emphasized the need for comprehensive TB education, improved financial support mechanisms, and rights-based approaches to reduce stigma and enhance healthcare access.",
          ],
        },
        {
          key: "Conclusion:",
          value: [
            "Addressing financial barriers, enhancing TB education, and promoting rights-based strategies are crucial for improving TB care and reducing stigma in Senegal.",
          ],
        },
      ],
    },
    30: {
      header: "Évaluation au Sénégal",
      description: `L'évaluation CRG menée en 2020 a mis en évidence plusieurs obstacles liés aux services de lutte contre la tuberculose (TB). Voici un résumé des conclusions.`,
      content: [
        {
          key: "Obstacles à l'Accessibilité:",
          value: [
            "Contraintes Financières : 86,6% des CDT ont cité le manque de ressources financières comme un obstacle majeur pour les patients TB.",
            "Défis Géographiques : 40% ont mentionné l'éloignement des établissements de santé comme un obstacle significatif à l'accès aux services.",
            "Taux d'Abandon du Traitement : 26,67% des patients TB ont abandonné le traitement dans les deux mois en raison d'une compréhension inadéquate de la TB et des droits humains.",
          ],
        },
        {
          key: "Qualité des Services:",
          value: [
            "Satisfaction des Patients : Malgré les défis, les patients TB ont exprimé leur satisfaction quant à la qualité des services fournis lors de leurs rendez-vous, ce qui a influencé leur adhésion au traitement.",
          ],
        },
        {
          key: "Stigmatisation et Discrimination:",
          value: [
            "Perceptions : Les patients TB et les populations vulnérables ont signalé divers degrés de stigmatisation dans les communautés, sur les lieux de travail et dans les établissements de santé.",
            "Recommandations : Il est nécessaire de mettre en place une éducation complète sur la TB, d'améliorer les mécanismes de soutien financier et d'adopter des approches basées sur les droits pour réduire la stigmatisation et améliorer l'accès aux soins de santé.",
          ],
        },
        {
          key: "Conclusion:",
          value: [
            "L'élimination des obstacles financiers, l'amélioration de l'éducation sur la TB et la promotion de stratégies basées sur les droits sont essentielles pour améliorer les soins contre la TB et réduire la stigmatisation au Sénégal.",
          ],
        },
      ],
    },
  },
};
