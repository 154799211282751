export default [
  {
    key: "20",
    value: [
      { report: require("../../files/action/cameroon/vf_CRG-Rapport_2020_Cameroun.pdf") },
      {
        english: require("../../files/action/cameroon/CAMEROUN_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB.docx"),
      },
      {
        french: require("../../files/action/cameroon/CAMEROUN_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB_French.docx"),
      },
    ],
  },
  {
    key: "30",
    value: [
      {
        report: require("../../files/action/benin/Plan_dAction_et_Cadre_Responsabilite_CRG_TB_Benin_2024-2026_Version_Pre-finale.pdf"),
      },
      {
        english: require("../../files/action/benin/Benin_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB.docx"),
      },
      {
        french: require("../../files/action/benin/Benin_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB_French.docx"),
      },
    ],
  },
  {
    key: "40",
    value: [
      {
        report: require("../../files/action/rdc/CRG_ASSEMENT_ON_TB_IN_DRC_Final_Report.pdf"),
      },
      {
        english: require("../../files/action/rdc/RDC_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB.docx"),
      },
      {
        french: require("../../files/action/rdc/RDC_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB_French.docx"),
      },
    ],
  },
  {
    key: "50",
    value: [],
  },
  {
    key: "60",
    value: [
      {
        english: require("../../files/action/niger/Niger_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB.docx"),
      },
      {
        french: require("../../files/action/niger/Niger_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB_French.docx"),
      },
    ],
  },
  {
    key: "101",
    value: [
      {
        english: require("../../files/action/mali/MALI_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB.docx"),
      },
      {
        french: require("../../files/action/mali/MALI_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB_French.docx"),
      },
    ],
  },
  {
    key: "103",
    value: [
      {
        report: require("../../files/action/tchad/TCHAD_Evaluation_CRG_TB_dans_lacces_des_PCV_aux_services_TB.pdf"),
      },
      {
        english: require("../../files/action/tchad/TCHAD_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB.docx"),
      },
      {
        french: require("../../files/action/tchad/TCHAD_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB_French.docx"),
      },
    ],
  },
  {
    key: "104",
    value: [
      {
        report: require("../../files/action/senegal/PJ_2 _RAPPORT_ETUDE_STIGMATISATION_TB_Senegal.pdf"),
      },
      {
        english: require("../../files/action/senegal/Senegal_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB.docx"),
      },
      {
        french: require("../../files/action/senegal/Senegal_Fiche_CRG_TB_Regional_Dashboard_DRAF_TB_French.docx"),
      },
    ],
  },
];
