import { React, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import UNHLMCountrySelect from "../UNHLMCountrySelect";

// icons import
import targetMainIcon from "../../../assets/images/homepageIcons/target.svg";
import infoIcon from "../../../assets/images/homepageIcons/infoicon.svg";
import AccIcon from "../../../assets/images/unhlmpageIcons/task-list.svg";
import imgUrl from "../../../assets/images/imgUrl";
import SearchIcon from "@mui/icons-material/Search";

function UNHLMActivtiesDashboardPage() {
  // select country component
  const [countryAcc, setCountryAcc] = useState(10); // Set the initial value to 10 for "Select country"
  const [UNHLMActCountries, setUNHLMActCountries] = useState(10); // Set the initial value to 10 for "Select country"
  const handleCountryAccChange = (event) => {
    setCountryAcc(event.target.value);
  };

  const handleUNHLMActCountriesChange = (event) => {
    setUNHLMActCountries(event.target.value);
  };
  return (
    <div className="commonPageCardSubSection">
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3} className="subCountries-mobile-d-none">
            <Card className="defineCountryHeight">
              <CardContent>
                <div>
                  <Typography className="text-center listCountryHeader">
                    Countries
                  </Typography>
                  <div className="listCountries">
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <img src={imgUrl.cameroonLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Cameroon" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.beninLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Benin" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.rdcLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="RDC" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.congoLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Congo" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.nigerLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Niger" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.burkinaLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Burkina Faso" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.guineeLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Guinée" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.burundiLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Burundi" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.togoLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Togo" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.maliLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Mali" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.mauritanieLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Mauritanie" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.chadLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Chad" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.senegalLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Sénégal" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.gabonLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Gabon" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.centrafriqueLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Centrafrique" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {" "}
                            <img src={imgUrl.coteLogo}></img>
                          </ListItemIcon>
                          <ListItemText primary="Côte d’Ivoire" />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="mt-10px defineScoreHeight">
              <CardContent className="pb-0px">
                <div className="d-flex justify-content-start align-items-center">
                  <IconButton variant="contained" className="lightOrangeButton">
                    <img src={targetMainIcon}></img>
                  </IconButton>
                  <div className="ml-10px">
                    <Typography className="cardHeaders ">
                      UNHLM Post-Activities Score Card 2023
                    </Typography>
                  </div>
                </div>
                <div>
                  <Typography className="cardCounts mt-10px">7.6/10</Typography>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <img src={infoIcon} className="infoImg"></img>
                </div>
              </CardContent>
            </Card>
          </Grid>

          {/* mobile ui for countries */}
          <Grid item xs={12} className="subCountries-desktop-d-none">
            <div className="countryDropdown mt-0px">
              <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
                <SearchIcon className="searchIcon"></SearchIcon>
                <FormControl fullWidth className="countrySelectField">
                  {/* <InputLabel id="demo-simple-select-label">
                          Age
                        </InputLabel> */}
                  <Select
                    labelId="demo-UNHLMActCountries-select-label"
                    id="demo-UNHLMActCountries-select"
                    value={UNHLMActCountries}
                    label=""
                    onChange={handleUNHLMActCountriesChange}
                  >
                    <MenuItem value={10} disabled>
                      Select Country
                      {/* Placeholder text or label for the initial option */}
                    </MenuItem>
                    <MenuItem value={20}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.cameroonLogo}></img>
                        <span className="ml-10px countryName">Cameroon</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={30}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.beninLogo}></img>
                        <span className="ml-10px countryName">Benin</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={40}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.rdcLogo}></img>
                        <span className="ml-10px countryName">RDC</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={50}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.congoLogo}></img>
                        <span className="ml-10px countryName">Congo</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={60}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.nigerLogo}></img>
                        <span className="ml-10px countryName">Niger</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={70}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.burkinaLogo}></img>
                        <span className="ml-10px countryName">
                          Burkina Faso
                        </span>
                      </div>
                    </MenuItem>
                    <MenuItem value={80}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.guineeLogo}></img>
                        <span className="ml-10px countryName">Guinée</span>
                      </div>
                    </MenuItem>{" "}
                    <MenuItem value={90}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.burundiLogo}></img>
                        <span className="ml-10px countryName">Burundi</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={100}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.togoLogo}></img>
                        <span className="ml-10px countryName">Togo</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={101}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.maliLogo}></img>
                        <span className="ml-10px countryName">Mali</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={102}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.mauritanieLogo}></img>
                        <span className="ml-10px countryName">Mauritanie</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={103}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.chadLogo}></img>
                        <span className="ml-10px countryName">Chad</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={104}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.senegalLogo}></img>
                        <span className="ml-10px countryName">Sénégal</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={105}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.gabonLogo}></img>
                        <span className="ml-10px countryName">Gabon</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={106}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.centrafriqueLogo}></img>
                        <span className="ml-10px countryName">
                          Centrafrique
                        </span>
                      </div>
                    </MenuItem>
                    <MenuItem value={106}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={imgUrl.coteLogo}></img>
                        <span className="ml-10px countryName">
                          Côte d’Ivoire
                        </span>
                      </div>
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <Card className="mt-10px defineScoreHeight mobile-DataLink">
              <CardContent className="pb-0px">
                <div className="d-flex justify-content-start align-items-center">
                  <IconButton variant="contained" className="lightOrangeButton">
                    <img src={targetMainIcon}></img>
                  </IconButton>
                  <div className="ml-10px">
                    <Typography className="cardHeaders ">
                      UNHLM Post-Activities Score Card 2023
                    </Typography>
                  </div>
                </div>
                <div>
                  <Typography className="cardCounts mt-10px">7.6/10</Typography>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <img src={infoIcon} className="infoImg"></img>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={9}>
            <Card className="defineCountryUNCardHeight">
              <CardContent>
                <div className="">
                  <Typography className="unhlmHeading">
                    {" "}
                    UNHLM TB Key Asks Country Activities
                  </Typography>
                  <div className="keyPara mt-10px">
                    <Card className="bg-pink">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={5}>
                            <div className="border-pink">
                              <Typography>Key Ask # 1</Typography>
                              <p className="keyParaSection mb-0px">
                                Reach all people affected by tuberculosis (TB)
                                with prevention, diagnosis, treatment, and care
                                by implementing evidence-based and quality
                                interventions and tools as per the latest
                                international guidelines.
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={7}>
                            <div className="">
                              <Typography>Activities</Typography>
                              <p className="mb-0px keyParaSection">Example</p>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Card className="bg-blue mt-10px">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={5}>
                            <div className="border-blue">
                              <Typography>Key Ask # 2</Typography>
                              <p className="keyParaSection mb-0px">
                                Ensure all national TB responses are equitable,
                                inclusive, gender-sensitive, rights-based and
                                people-centered.
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={7}>
                            <div className="">
                              <Typography>Activities</Typography>
                              <p className="mb-0px keyParaSection">Example</p>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Card className="bg-orange mt-10px">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={5}>
                            <div className="border-yellow">
                              <Typography>Key Ask #3</Typography>
                              <p className="keyParaSection mb-0px">
                                Accelerate the research, development, roll-out,
                                and access to new TB vaccines, diagnostics,
                                drugs, and other essential new tools, including
                                digital health technologies geared to the needs
                                of the most neglected, key and vulnerable
                                populations.
                              </p>
                            </div>
                          </Grid>

                          <Grid item xs={7}>
                            <div className="ml-15px">
                              <Typography>Activities</Typography>
                              <p className="mb-0px keyParaSection">Example</p>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="mt-10px defineReportRightCardHeight mobile-DataLink">
              <CardContent>
                <Typography className="reportHeading">
                  To see the details of this report follow the link below:{" "}
                </Typography>
                <Link
                  href="https://stoptb.org/assets/documents/global/awards/cfcs/r9/20268_Factsheet_DRAF_TB_v02_JG_001.pdf"
                  className="reportLink"
                >
                  https://stoptb.org/assets/documents/global/awards/cfcs/r9/20268_Factsheet_DRAF_TB_v02_JG_001.pdf
                </Link>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className="mt-10px">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Grid item xs={1}></Grid>

                  <Grid item xs={12} md={6} sm={12} lg={6}>
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={AccIcon} alt="location"></img>
                      <Typography className="ml-15px">
                        Go to Country Key Asks Accountability
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4} sm={12} lg={4}>
                    {" "}
                    <div className="countryDropdown mt-0px">
                      <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
                        <SearchIcon className="searchIcon"></SearchIcon>
                        <FormControl fullWidth className="countrySelectField">
                          {/* <InputLabel id="demo-simple-select-label">
                          Age
                        </InputLabel> */}
                          <Select
                            labelId="demo-countryAcc-select-label"
                            id="demo-countryAcc-select"
                            value={countryAcc}
                            label=""
                            onChange={handleCountryAccChange}
                          >
                            <MenuItem value={10} disabled>
                              Select Country
                              {/* Placeholder text or label for the initial option */}
                            </MenuItem>
                            <MenuItem value={20}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.cameroonLogo}></img>
                                <span className="ml-10px countryName">
                                  Cameroon
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={30}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.beninLogo}></img>
                                <span className="ml-10px countryName">
                                  Benin
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={40}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.rdcLogo}></img>
                                <span className="ml-10px countryName">RDC</span>
                              </div>
                            </MenuItem>
                            <MenuItem value={50}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.congoLogo}></img>
                                <span className="ml-10px countryName">
                                  Congo
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={60}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.nigerLogo}></img>
                                <span className="ml-10px countryName">
                                  Niger
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={70}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.burkinaLogo}></img>
                                <span className="ml-10px countryName">
                                  Burkina Faso
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={80}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.guineeLogo}></img>
                                <span className="ml-10px countryName">
                                  Guinée
                                </span>
                              </div>
                            </MenuItem>{" "}
                            <MenuItem value={90}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.burundiLogo}></img>
                                <span className="ml-10px countryName">
                                  Burundi
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={100}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.togoLogo}></img>
                                <span className="ml-10px countryName">
                                  Togo
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={101}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.maliLogo}></img>
                                <span className="ml-10px countryName">
                                  Mali
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={102}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.mauritanieLogo}></img>
                                <span className="ml-10px countryName">
                                  Mauritanie
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={103}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.chadLogo}></img>
                                <span className="ml-10px countryName">
                                  Chad
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={104}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.senegalLogo}></img>
                                <span className="ml-10px countryName">
                                  Sénégal
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={105}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.gabonLogo}></img>
                                <span className="ml-10px countryName">
                                  Gabon
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={106}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.centrafriqueLogo}></img>
                                <span className="ml-10px countryName">
                                  Centrafrique
                                </span>
                              </div>
                            </MenuItem>
                            <MenuItem value={106}>
                              <div className="d-flex justify-content-start align-items-center">
                                <img src={imgUrl.coteLogo}></img>
                                <span className="ml-10px countryName">
                                  Côte d’Ivoire
                                </span>
                              </div>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default UNHLMActivtiesDashboardPage;
