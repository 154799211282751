export const actions = {
  20: {
    20: {
      header: "",
      description: "",
      content: [
        {
          key: "",
          value: [],
        },
      ],
    },
    30: {
      header: "",
      description: "",
      content: [
        {
          key: "",
          value: [],
        },
      ],
    },
  },
  30: {
    20: {
      header: "Action Plan Goal",
      description:
        "This action plan essentially aims to integrate the gender and human rights approach in the fight against tuberculosis in Benin.",
      content: [
        {
          key: "Objectives",
          value: [
            "The overall objective is to contribute to the improvement of TB care through the transformation of the patient-centred and human rights-based response to TB in Benin. The specific objectives of the TB CRG Action Plan align with the 3 pillars of the WHO End TB strategy.",
          ],
        },
        {
          key: "Pillar 1: Integrated and patient-centred care and prevention to ensure universal access to TB care and support services for all.",
          value: [
            "Objective 1.1: Place people, affected communities, TB survivors and key and vulnerable populations at the centre of the fight as equal partners in the TB response (planning, implementation, monitoring, advocacy.",
            `Objective 1.2: Mobilize and empower key and vulnerable populations (PLHIV, PPL, Diabetics, Pregnant Women, Children under 05 years of age, IDUs, LGBTQ and Residents/Residents of Hard-to-Access Areas) in the fight against TB.`,
            `Objective 1.3: Ensure dignity and consideration for key and vulnerable TB populations in Benin`,
          ],
        },
        {
          key: "Pillar 2: Bold policies and support systems, and strengthened collaboration of communities, civil society and the private sector to ensure universal access to integrated, patient-centred TB care and prevention for all.",
          value: [
            "Objective 2.1: Strengthen the consideration of gender and human rights aspects in the management of tuberculosis.",
            `Objective 2.2: Ensure social protection and action on the other determinants of TB.`,
          ],
        },
        {
          key: "Pillar 3: Scaling up research and innovation.",
          value: [
            "Objective 3.1: Monitor the progress of all stakeholders in the realization of the right to health through the accountability tools provided to governments, the international community and civil society.",
            `Objective 3.2: Involve other ministries, such as ministries of finance, education, social welfare, labour, etc. in the fight against TB by creating a working group with civil society to discuss and identify solutions to address the upstream determinants (gender equality, access to education for all, social inclusion, etc.) that drive the TB endemic.`,
            "Objective 3.3: Establish a platform to document and share best practices, support advocacy and social mobilization around TB human rights.",
          ],
        },
      ],
    },
    30: {
      header: "Objectif du Plan d'Action",
      description:
        "Ce plan d'action vise essentiellement à intégrer l'approche de genre et des droits humains dans la lutte contre la tuberculose au Bénin.",
      content: [
        {
          key: "Objectifs",
          value: [
            `L'objectif général est de contribuer à l'amélioration des soins de la tuberculose par la transformation de la réponse centrée sur le patient et basée sur les droits humains à la tuberculose au Bénin.`,
            `Les objectifs spécifiques du Plan d'Action TB CRG s'alignent sur les 3 piliers de la stratégie End TB de l'OMS.`,
          ],
        },
        {
          key: "Pilier 1 : Soins intégrés et centrés sur le patient et prévention pour assurer un accès universel aux soins et services de soutien contre la tuberculose pour tous.",
          value: [
            `Objectif 1.1 : Placer les personnes, les communautés affectées, les survivants de la tuberculose et les populations clés et vulnérables au centre de la lutte en tant que partenaires égaux dans la réponse à la tuberculose (planification, mise en œuvre, suivi, plaidoyer).`,
            `Objectif 1.2 : Mobiliser et autonomiser les populations clés et vulnérables (PVVIH, PPL, diabétiques, femmes enceintes, enfants de moins de 5 ans, UDI, LGBTQ et résidents/habitants de zones difficiles d'accès) dans la lutte contre la tuberculose.`,
            `Objectif 1.3 : Assurer la dignité et la considération des populations clés et vulnérables atteintes de tuberculose au Bénin.`,
          ],
        },
        {
          key: "Pilier 2 : Politiques audacieuses et systèmes de soutien, et collaboration renforcée des communautés, de la société civile et du secteur privé pour assurer un accès universel aux soins et à la prévention intégrés et centrés sur le patient contre la tuberculose pour tous.",
          value: [
            `Objectif 2.1 : Renforcer la prise en compte des aspects de genre et des droits humains dans la gestion de la tuberculose.`,
            `Objectif 2.2 : Assurer la protection sociale et l'action sur les autres déterminants de la tuberculose.`,
            `Objectif 1.3 : Assurer la dignité et la considération des populations clés et vulnérables atteintes de tuberculose au Bénin.`,
          ],
        },
        {
          key: "Pilier 3 : Accélération de la recherche et de l'innovation.",
          value: [
            `Objectif 3.1 : Suivre les progrès de tous les acteurs dans la réalisation du droit à la santé grâce aux outils de responsabilité fournis aux gouvernements, à la communauté internationale et à la société civile.`,
            `Objectif 3.2 : Impliquer d'autres ministères, tels que les ministères des finances, de l'éducation, de la protection sociale, du travail, etc., dans la lutte contre la tuberculose en créant un groupe de travail avec la société civile pour discuter et identifier des solutions pour traiter les déterminants en amont (égalité des genres, accès à l'éducation pour tous, inclusion sociale, etc.) qui alimentent l'endémie de la tuberculose.`,
            `Objectif 3.3 : Établir une plateforme pour documenter et partager les meilleures pratiques, soutenir le plaidoyer et la mobilisation sociale autour des droits humains relatifs à la tuberculose.`,
          ],
        },
      ],
    },
  },
  40: {
    20: {
      header: "Action Plan",
      description:
        "The specific objectives of the CRG action plan align with the 7 areas of intervention in the Stop TB Partnership's practical guide to a costed CRG action plan.",
      content: [
        {
          key: "The implementation of this action plan will be based on the following 5 interventions:",
          value: [
            `Intervention 1: Elimination of TB-related stigma and discrimination: This intervention aims to reduce or eliminate the stigma associated with TB in the community, health facilities and other settings where people affected by TB and PCV are associated.`,
            `Intervention 2: Provision of people-centred and rights-based TB services in health settings: This intervention aims to promote stigma and discrimination-free models of TB services in diagnosis, treatment, prevention and integrated care for people affected by TB.  the PCV. This includes rights-based programming as well as approaches to empowering people affected by TB and VRPs such as OneImpact_CLM.`,
            `Intervention 3: Legal Education ("Know Your Rights"): The intervention includes empowerment activities for communities affected by TB and key and vulnerable populations.`,
            `Intervention 4: Community mobilization and awareness, including support for groups led by TB survivors: This intervention revolves around community awareness-raising activities, advocacy to address the harms and other barriers related to TB stigma and human rights across the country.`,
            `Intervention 5: Monitoring and reform of policies, regulations and law: This intervention aims to improve the TB legal environment through legal reforms, the development of new TB care policies, the monitoring of TB-related stigma, advocacy and sensitization of parliamentarians.`,
          ],
        },
        {
          key: "Action Plan Implementation Partnership - In order to support the implementation of the CRG Action Plan, a wide range of actors is needed, highlighting the need for a multi-sectoral approach. As outlined in the MAF Action Plan, partners will include:",
          value: [
            `PNLT through its staff will have to provide strategic guidance/advice for implementation with gender and human rights aspects in collaboration with STP TB DRC and other sectors of life.`,
            `Club des Amis Damien, Community Network of TB Survivors: will be a leader in the implementation of the project by involving the other members of Stop TB.`,
            `STOP TB DRC: Will ensure the influence of the activities of the various groups distinct from the VCP, human rights and gender and will support the PNLT in the realization of major events related to human rights and gender TB.`,
            `Representatives of the health program outside of TB (NLLP, PNMLS, Nutrition, PNSR, etc).`,
            `Ministries of Education, Justice, Social Affairs, Labour and the Environment.`,
          ],
        },
      ],
    },
    30: {
      header: "Plan d'Action",
      description:
        "Les objectifs spécifiques du plan d'action CRG sont alignés avec les 7 domaines d'intervention du guide pratique du Partenariat Stop TB pour un plan d'action CRG budgétisé.",
      content: [
        {
          key: "La mise en œuvre de ce plan d'action sera basée sur les 5 interventions suivantes:",
          value: [
            `Intervention 1 : Élimination de la stigmatisation et de la discrimination liées à la TB : Cette intervention vise à réduire ou éliminer la stigmatisation associée à la TB dans la communauté, les établissements de santé et autres lieux où les personnes affectées par la TB et les PVVIH sont présentes.`,
            `Intervention 2 : Fourniture de services de TB centrés sur les personnes et basés sur les droits dans les milieux de santé : Cette intervention vise à promouvoir des modèles de services TB exempts de stigmatisation et de discrimination dans le diagnostic, le traitement, la prévention et les soins intégrés pour les personnes affectées par la TB et les PVVIH. Cela inclut des programmes basés sur les droits ainsi que des approches pour autonomiser les personnes affectées par la TB et les PVVIH comme OneImpact_CLM.`,
            `Intervention 3 : Éducation Juridique ("Connaître Vos Droits") : L'intervention comprend des activités d'émancipation pour les communautés affectées par la TB et les populations clés et vulnérables.`,
            `Intervention 4 : Mobilisation et Sensibilisation Communautaires, y compris le soutien aux groupes dirigés par des survivants de la TB : Cette intervention tourne autour des activités de sensibilisation communautaire, du plaidoyer pour aborder les préjudices et autres obstacles liés à la stigmatisation de la TB et aux droits humains à travers le pays.`,
            `Intervention 5 : Suivi et Réforme des Politiques, Réglementations et Lois : Cette intervention vise à améliorer l'environnement juridique de la TB par des réformes législatives, le développement de nouvelles politiques de soins de la TB, le suivi de la stigmatisation liée à la TB, le plaidoyer et la sensibilisation des parlementaires.`,
          ],
        },
        {
          key: "Partenariat pour la Mise en Œuvre du Plan d'Action - Afin de soutenir la mise en œuvre du Plan d'Action CRG, une large gamme d'acteurs est nécessaire, soulignant la nécessité d'une approche multisectorielle. Comme décrit dans le Plan d'Action MAF, les partenaires incluront:",
          value: [
            `Le PNLT, par l'intermédiaire de son personnel, devra fournir des conseils stratégiques pour la mise en œuvre en tenant compte des aspects de genre et des droits humains en collaboration avec STP TB RDC et d'autres secteurs de la vie.`,
            `Le Club des Amis Damien, Réseau Communautaire des Survivants de la Tuberculose : sera un leader dans la mise en œuvre du projet en impliquant les autres membres de Stop TB.`,
            `STOP TB RDC : Assurera l'influence des activités des divers groupes distincts de la PVVIH, des droits humains et du genre et soutiendra le PNLT dans la réalisation des événements majeurs liés aux droits humains et à la tuberculose liée au genre.`,
            `La Ligue Nationale Anti-Tuberculose et Anti-Lèpre du Congo : aura la spécificité de plaider auprès des législateurs en plus de soutenir les acteurs sur le terrain dans la réduction de la stigmatisation.`,
            `Les représentants des programmes de santé en dehors de la TB (PNLLP, PNMLS, Nutrition, PNSR, etc.).`,
            `Les ministères de l'Éducation, de la Justice, des Affaires Sociales, du Travail et de l'Environnement`,
          ],
        },
      ],
    },
  },
  60: {
    20: {
      header: "Action Plan",
      description:
        "The overall objective is to contribute to the improvement of TB care through patient-centred and human rights-based care in Niger. The specific objectives of the TB CRG Action Plan align with the 3 pillars of the WHO's End TB strategy",
      content: [
        {
          key: "Pillar 1: Integrated and patient-centred care and prevention to ensure universal access to TB care and support services for all.",
          value: [
            `Objective 1.1: Place people, affected communities, TB survivors and key and vulnerable populations at the centre of the fight as equal partners in the TB response (planning, implementation, monitoring, advocacy).`,
            `Objective 1.2: Mobilize and empower key and vulnerable populations (PLHIV, PPL, Diabetics, Pregnant Women, Children under 05 years of age, IDUs, LGBTQ and Residents/Residents of Hard-to-Access Areas) in the fight against TB.`,
            `Objective 1.3: Ensure dignity and consideration for key and vulnerable populations TB.`,
          ],
        },
        {
          key: "Pillar 2: Bold policies and support systems, and strengthened collaboration of communities, civil society and the private sector to ensure universal access to integrated, patient-centred TB care and prevention for all.",
          value: [
            `Objective 2.1: Strengthen the consideration of gender and human rights aspects in the management of tuberculosis.`,
            `Objective 2.2: Ensure social protection and action on the other determinants of TB.`,
          ],
        },
        {
          key: "Pillar 3: Scaling up research and innovation.",
          value: [
            `Objective 3.1: Monitor the progress of all stakeholders in the realization of the right to health through the accountability tools provided to governments, the international community and civil society.`,
            `Objective 3.2: Involve other ministries, such as the Ministries of Finance, Education, Social Welfare, Labour, Livestock, etc., in the fight against tuberculosis by creating a working group with civil society to discuss and identify solutions to address the upstream determinants (gender equality, access to education for all, etc.).  social inclusion, etc.) that lead to TB endemic.`,
          ],
        },
      ],
    },
    30: {
      header: "Plan d'Action",
      description:
        "L'objectif général est de contribuer à l'amélioration des soins contre la TB par des soins centrés sur le patient et fondés sur les droits de l'homme au Niger. Les objectifs spécifiques du Plan d'Action TB CRG s'alignent sur les 3 piliers de la stratégie End TB de l'OMS",
      content: [
        {
          key: "Pilier 1 : Soins intégrés et centrés sur le patient et prévention pour garantir un accès universel aux soins et services de soutien contre la TB pour tous.",
          value: [
            `Objectif 1.1 : Placer les personnes, les communautés affectées, les survivants de la TB et les populations clés et vulnérables au centre de la lutte en tant que partenaires égaux dans la réponse à la TB (planification, mise en œuvre, suivi, plaidoyer).`,
            `Objectif 1.2 : Mobiliser et autonomiser les populations clés et vulnérables (PVVIH, PPL, Diabétiques, Femmes enceintes, Enfants de moins de 5 ans, UDIs, LGBTQ et Résidents/Résidents des zones difficiles d'accès) dans la lutte contre la TB.`,
            `Objectif 1.3 : Assurer la dignité et la considération pour les populations clés et vulnérables atteintes de TB.`,
          ],
        },
        {
          key: "Pilier 2 : Politiques audacieuses et systèmes de soutien, et renforcement de la collaboration des communautés, de la société civile et du secteur privé pour garantir un accès universel à des soins et une prévention de la TB intégrés et centrés sur le patient pour tous.",
          value: [
            `Objectif 2.1 : Renforcer la prise en compte des aspects de genre et des droits de l'homme dans la gestion de la tuberculose.`,
            `Objectif 2.2 : Assurer la protection sociale et agir sur les autres déterminants de la TB.`,
          ],
        },
        {
          key: "Pilier 3 : Intensification de la recherche et de l'innovation.",
          value: [
            `Objectif 3.1 : Suivre les progrès de tous les acteurs dans la réalisation du droit à la santé grâce aux outils de responsabilisation fournis aux gouvernements, à la communauté internationale et à la société civile.`,
            `Objectif 3.2 : Impliquer d'autres ministères, tels que les Ministères des Finances, de l'Éducation, de l'Aide Sociale, du Travail, de l'Élevage, etc., dans la lutte contre la tuberculose en créant un groupe de travail avec la société civile pour discuter et identifier des solutions pour aborder les déterminants en amont (égalité des sexes, accès à l'éducation pour tous, inclusion sociale, etc.) qui conduisent à l'endémie de la TB.`,
          ],
        },
      ],
    },
  },
  101: {
    20: {
      header: "",
      description: "",
      content: [
        {
          key: "",
          value: [],
        },
      ],
    },
    30: {
      header: "",
      description: "",
      content: [
        {
          key: "",
          value: [],
        },
      ],
    },
  },
  103: {
    20: {
      header: "Action Plan",
      description:
        "BUDGETED OPERATIONAL PLAN FOR COMMUNITIES, HUMAN RIGHTS AND GENDER IN THE FIGHT AGAINST TUBERCULOSIS IN CHAD 2024-2028",
      content: [
        {
          key: "Gender Mainstreaming Recommendations:",
          value: [
            `Gender-Specific Communication: Develop and validate a strategic communication plan targeting men and women to improve awareness and access to TB services.`,
            `Awareness Campaigns: Conduct monthly campaigns in locations frequented by men and boys (cafes, bars) to educate on TB signs, transmission, and treatment.`,
            `Community Health Worker (CHW) Support: Equip CHWs with awareness kits to enhance community-level TB education and outreach.`,
            `Women's Group Engagement: Mobilize women's group leaders to educate women and girls about TB signs and combat stigma.`,
            `Integration into Maternal and Child Health: Incorporate TB screening into maternal, newborn, child, and adolescent health services to improve early detection.`,
          ],
        },
        {
          key: "Undernourishment and TB Risk:",
          value: [
            `Food Support: Provide nutritional support to TB patients in food-insecure areas to improve treatment adherence and outcomes.`,
          ],
        },
        {
          key: "Economic Deprivation and TB Risk:",
          value: [
            `Cost Study: Conduct a study on catastrophic costs borne by TB patients in Chad to identify economic barriers.`,
          ],
        },
        {
          key: "Reaching Vulnerable Populations: People Living with HIV/AIDS (PLHA):",
          value: [
            `Integration with HIV Programs: Integrate TB components into HIV training modules for police and detention center personnel.`,
            `Community-Based Screening: Involve community health workers in TB screening among PLHA to enhance case detection.`,
            `Collaboration Strengthening: Enhance collaboration between TB and HIV programs at all levels to improve service integration.`,
            `One-Stop Shop Strategy: Expand integrated service delivery to include TB services at all CDTs and HIV care sites.`,
          ],
        },
        {
          key: "Reaching Vulnerable Populations: Diabetes:",
          value: [
            `Diabetes Collaboration: Strengthen collaboration between TB and diabetes management programs to improve TB screening and care among diabetics.`,
          ],
        },
        {
          key: "Reaching Vulnerable Populations: Inmates:",
          value: [
            `Prisoner Specific Initiatives: Implement tailored TB initiatives within prison settings to address unique challenges.`,
          ],
        },
        {
          key: "Reaching Vulnerable Populations: Nomads:",
          value: [
            `Formal Partnerships: Establish formal partnerships between PNLT and the National Nomadic Health Programme to enhance TB services among nomadic populations.`,
            `Quarterly Campaigns: Conduct joint health campaigns focusing on TB and other health issues among mobile pastoralists.`,
          ],
        },
        {
          key: "Human Rights Programme Recommendations:",
          value: [],
        },
        {
          key: "Stigma and Discrimination Elimination:",
          value: [
            `Integrate TB components into HIV programs targeting stigma reduction.`,
            `Broadcast TB awareness and stigma reduction messages via radio and television.`,
            `Integrate TB management into non-TB health services to reduce stigma in healthcare settings.`,
            `Establish a national committee on gender and human rights in health to coordinate efforts.`,
          ],
        },
        {
          key: "People-Centered TB Services:",
          value: [
            `Include stigma, discrimination, and human rights in TB training for healthcare workers.`,
            `Advocate for inclusion of TB-related education in pre-service training for healthcare professionals.`,
          ],
        },
        {
          key: "Legal and Justice Support:",
          value: [
            `Distribute the Declaration of Rights of People Affected by TB to all TB patients.`,
            `Establish legal partnerships and a legal aid fund to support TB patients' rights.`,
          ],
        },
        {
          key: "Capacity Building and Documentation:",
          value: [
            `Conduct workshops for parliamentarians on public health, human rights, and law reform.`,
            `Document cases of unauthorized disclosure of TB patient information for advocacy purposes.`,
          ],
        },
        {
          key: "These recommendations aim to address gender disparities, socioeconomic challenges, and human rights issues that hinder effective TB prevention, diagnosis, and treatment in Chad.",
          value: [],
        },
      ],
    },
    30: {
      header: "Plan d'Action",
      description:
        "PLAN OPÉRATIONNEL BUDGÉTISÉ POUR LES COMMUNAUTÉS, LES DROITS HUMAINS ET LE GENRE DANS LA LUTTE CONTRE LA TUBERCULOSE AU TCHAD 2024-2028",
      content: [
        {
          key: "Recommandations pour l'Intégration du Genre:",
          value: [
            "Communication Spécifique au Genre : Développer et valider un plan de communication stratégique ciblant les hommes et les femmes pour améliorer la sensibilisation et l'accès aux services de TB.",
            `Campagnes de Sensibilisation : Mener des campagnes mensuelles dans des lieux fréquentés par les hommes et les garçons (cafés, bars) pour éduquer sur les signes, la transmission et le traitement de la TB.`,
            `Soutien des Agents de Santé Communautaire (ASC) : Équiper les ASC de kits de sensibilisation pour renforcer l'éducation et la sensibilisation à la TB au niveau communautaire.`,
            `Mobilisation des Groupes de Femmes : Mobiliser les leaders des groupes de femmes pour éduquer les femmes et les filles sur les signes de la TB et combattre la stigmatisation.`,
            `Intégration dans la Santé Maternelle et Infantile : Intégrer le dépistage de la TB dans les services de santé maternelle, néonatale, infantile et adolescente pour améliorer la détection précoce.`,
          ],
        },
        {
          key: "Sous-alimentation et Risque de TB:",
          value: [
            "Soutien Alimentaire : Fournir un soutien nutritionnel aux patients TB dans les zones en insécurité alimentaire pour améliorer l'adhésion au traitement et les résultats.",
          ],
        },
        {
          key: "Privation Économique et Risque de TB:",
          value: [
            "Étude des Coûts : Mener une étude sur les coûts catastrophiques supportés par les patients TB au Tchad pour identifier les barrières économiques.",
          ],
        },
        {
          key: "Atteindre les Populations Vulnérables : Personnes Vivant avec le VIH/SIDA (PVVIH):",
          value: [
            "Intégration avec les Programmes VIH : Intégrer des composants TB dans les modules de formation VIH pour le personnel des postes de police et des centres de détention.",
            "Dépistage Communautaire : Impliquer les agents de santé communautaire dans le dépistage de la TB parmi les PVVIH pour améliorer la détection des cas.",
            "Renforcement de la Collaboration : Renforcer la collaboration entre les programmes TB et VIH à tous les niveaux pour améliorer l'intégration des services.",
            "Stratégie de Guichet Unique : Étendre la prestation de services intégrés pour inclure les services TB dans tous les CDTs et les sites de soins VIH.",
          ],
        },
        {
          key: "Atteindre les Populations Vulnérables: Diabétiques:",
          value: [
            "Collaboration avec le Diabète : Renforcer la collaboration entre les programmes de gestion de la TB et du diabète pour améliorer le dépistage et les soins TB parmi les diabétiques.",
          ],
        },
        {
          key: "Atteindre les Populations Vulnérables: Détenus:",
          value: [
            "Initiatives Spécifiques aux Prisonniers : Mettre en œuvre des initiatives TB adaptées dans les établissements pénitentiaires pour relever les défis uniques.",
          ],
        },
        {
          key: "Atteindre les Populations Vulnérables: Nomades:",
          value: [
            "Partenariats Formels : Établir des partenariats formels entre le PNLT et le Programme National de Santé des Nomades pour améliorer les services TB parmi les populations nomades.",
            "Campagnes Trimestrielles : Mener des campagnes de santé conjointes axées sur la TB et d'autres problèmes de santé parmi les pasteurs mobiles.",
          ],
        },
        {
          key: "Recommandations du Programme des Droits Humains:",
          value: [],
        },
        {
          key: "Élimination de la Stigmatisation et de la Discrimination:",
          value: [
            "Intégrer des Composants TB dans les Programmes VIH : Intégrer des composants TB dans les programmes VIH visant à réduire la stigmatisation.",
            "Messages de Sensibilisation : Diffuser des messages de sensibilisation à la TB et de réduction de la stigmatisation via la radio et la télévision.",
            "Intégration des Services TB : Intégrer la gestion de la TB dans les services de santé non-TB pour réduire la stigmatisation dans les établissements de santé.",
            "Comité National sur le Genre et les Droits Humains : Établir un comité national sur le genre et les droits humains dans la santé pour coordonner les efforts",
          ],
        },
        {
          key: "Services TB Centrés sur les Personnes:",
          value: [
            "Formation sur les Droits Humains : Inclure la stigmatisation, la discrimination et les droits humains dans la formation sur la TB pour les travailleurs de la santé.",
            "Éducation sur la TB : Plaider pour l'inclusion de l'éducation liée à la TB dans la formation préalable à l'entrée en fonction des professionnels de santé.",
          ],
        },
        {
          key: "Soutien Juridique et Justice:",
          value: [
            "Distribution de la Déclaration des Droits : Distribuer la Déclaration des Droits des Personnes Affectées par la TB à tous les patients TB.",
            "Partenariats Juridiques : Établir des partenariats juridiques et un fonds d'aide juridique pour soutenir les droits des patients TB.",
          ],
        },
        {
          key: "Renforcement des Capacités et Documentation:",
          value: [
            "Ateliers pour les Parlementaires : Organiser des ateliers pour les parlementaires sur la santé publique, les droits humains et la réforme législative.",
            "Documentation des Cas de Divulgation Non Autorisée : Documenter les cas de divulgation non autorisée des informations des patients TB à des fins de plaidoyer.",
          ],
        },
        {
          key: "Ces recommandations visent à aborder les disparités de genre, les défis socio-économiques et les problèmes de droits humains qui entravent la prévention, le diagnostic et le traitement efficaces de la TB au Tchad.",
          value: [],
        },
      ],
    },
  },
  104: {
    20: {
      header: "Action Plan",
      description: "Objectives:",
      content: [
        {
          key: "General objective:",
          value: [
            "The overall objective is to contribute to the improvement of tuberculosis care by removing barriers to access to TB care in Senegal.",
          ],
        },
        {
          key: "Specific objectives:",
          value: [
            "The specific objectives of the action plan align with the 3 pillars of the End TB strategy.",
          ],
        },
        {
          key: "Pillar 1: Integrated and patient-centred care and prevention to ensure universal access to TB care and support services for all:",
          value: [
            "Objective 1.1: Involve key and vulnerable populations in the fight against.",
            "Objective 1.2: Engage treatment managers in integrating gender and human rights aspects into TB management.",
          ],
        },
        {
          key: "Pillar 2: Bold policies and support systems, and strengthened collaboration of communities, civil society and the private sector to ensure universal access to integrated, patient-centred TB care and prevention:",
          value: [
            "Objective 2.1: To ensure that the NTP takes into account gender and human rights aspects in the management of tuberculosis.",
            "Objective 2.2: Provide innovative financial and social protection to protect the right to health of key populations.",
          ],
        },
        {
          key: "Pillar 3: Scaling up research and innovation:",
          value: [
            "Objective 3.1: Promote partnership with other ministries to discuss and identify solutions to address the upstream determinants (gender equality, access to education for all, social inclusion, etc.) that drive TB endemic.",
            "Objective 3.2: Strengthen the documentation and sharing of best practices around human rights related to TB.",
          ],
        },
        {
          key: "The overall budget for the implementation of this action plan is estimated at 245,600,000 CFA francs. This amount is distributed as follows: 127,600,000 CFA francs for pillar 1; 18,500,000 CFA francs for pillar 2, 67,500,000 CFA francs for pillar 3 and 32,000,000 CFA francs for the monitoring and evaluation of the action plan. More than half of the budget (51.9%) is earmarked for the implementation of Pillar 1 activities. The most expensive activities are related to the provision of food kits at the CDT level, awareness-raising, and the investigation of catastrophic costs among tuberculosis patients.",
          value: [],
        },
      ],
    },
    30: {
      header: "Plan d'Action",
      description: "Objectifs:",
      content: [
        {
          key: "Objectif Général:",
          value: [
            "Contribuer à l'amélioration des soins contre la tuberculose en éliminant les obstacles à l'accès aux soins TB au Sénégal.",
          ],
        },
        {
          key: "Objectifs Spécifiques:",
          value: [
            "Les objectifs spécifiques du plan d'action s'alignent sur les 3 piliers de la stratégie End TB.",
          ],
        },
        {
          key: "Pilier 1 : Soins intégrés et centrés sur le patient et prévention pour garantir un accès universel aux soins et services de soutien contre la TB pour tous:",
          value: [
            "Objectif 1.1 : Impliquer les populations clés et vulnérables dans la lutte contre la TB.",
            "Objectif 1.2 : Engager les responsables du traitement à intégrer les aspects de genre et des droits humains dans la gestion de la TB.",
          ],
        },
        {
          key: "Pilier 2 : Politiques audacieuses et systèmes de soutien, et renforcement de la collaboration des communautés, de la société civile et du secteur privé pour garantir un accès universel à des soins et une prévention de la TB intégrés et centrés sur le patient:",
          value: [
            "Objectif 2.1 : Assurer que le PNT prenne en compte les aspects de genre et des droits humains dans la gestion de la tuberculose.",
            "Objectif 2.2 : Fournir une protection financière et sociale innovante pour protéger le droit à la santé des populations clés.",
          ],
        },
        {
          key: "Pilier 3 : Intensification de la recherche et de l'innovation:",
          value: [
            "Objectif 3.1 : Promouvoir le partenariat avec d'autres ministères pour discuter et identifier des solutions pour aborder les déterminants en amont (égalité des sexes, accès à l'éducation pour tous, inclusion sociale, etc.) qui conduisent à l'endémie de la TB.",
            "Objectif 3.2 : Renforcer la documentation et le partage des meilleures pratiques relatives aux droits humains liés à la TB.",
          ],
        },
        {
          key: "Le budget total pour la mise en œuvre de ce plan d'action est estimé à 245 600 000 francs CFA. Ce montant est réparti comme suit : 127 600 000 francs CFA pour le pilier 1 ; 18 500 000 francs CFA pour le pilier 2 ; 67 500 000 francs CFA pour le pilier 3 et 32 000 000 francs CFA pour le suivi et l'évaluation du plan d'action. Plus de la moitié du budget (51,9%) est allouée aux activités du pilier 1. Les activités les plus coûteuses sont liées à la fourniture de kits alimentaires au niveau des CDT, à la sensibilisation et à l'étude des coûts catastrophiques parmi les patients tuberculeux.",
          value: [],
        },
      ],
    },
  },
};
