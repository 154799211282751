import { React, useState } from "react";
import styled from "@emotion/styled/macro";
import {
  Card,
  MenuItem,
  FormControl,
  Select,
  TextField,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";

// ICONS iMPORT
import phoneIcon from "../../assets/images/feedbackIcons/telephone.svg";
import messageIcon from "../../assets/images/feedbackIcons/mail.svg";
import boxIcon from "../../assets/images/feedbackIcons/box.svg";
import imgUrl from "../../assets/images/imgUrl";
import { useLanguage } from "../../contexts/LanguageContext";
import feedbacktranslation from "../../config/feedbacktranslation";
// custom textfield ui
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E8E7E7", // Change this to your desired focused border color
  },
}));

function FeedbackPage() {
  // select country component
  const { language } = useLanguage();
  const [feedbackArea, setFeedbackArea] = useState(10); // Set the initial value to 10 for "Select country"
  const t = (key) => feedbacktranslation[language][key] || key;

  const handleFeedbackAreaChange = (event) => {
    setFeedbackArea(event.target.value);
  };

  return (
    <div className="commonPageCardSubSection feedbackSectionPage">
      <Card>
        <CardContent>
          <p className="mb-0px mt-0px feedbackPara">{t("description")}</p>
        </CardContent>
      </Card>
      <Card className="mt-10px">
        <CardContent>
          <Typography>{t("title")}</Typography>
          <div className="countryDropdown w-30">
            <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
              <FormControl fullWidth className="countrySelectField ml-0px">
                <Select
                  labelId="demo-feedbackArea-select-label"
                  id="demo-feedbackArea-select"
                  value={feedbackArea}
                  label=""
                  onChange={handleFeedbackAreaChange}
                >
                  <MenuItem value={10} disabled>
                    {t("dropdownfeedback")}
                  </MenuItem>
                  <MenuItem value={20} className="feedOption">
                    {t("menuitem1")}
                  </MenuItem>
                  <MenuItem value={30} className="feedOption">
                    {t("menuitem2")}
                  </MenuItem>
                  <MenuItem value={40} className="feedOption">
                    {t("menuitem3")}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="feedbackMsg mt-10px d-flex justify-content-between align-items-center feedbackMsgMobile">
            <div className="pt-0px">
              <CustomTextField
                id="outlined-first-static"
                label=""
                placeholder={t("firstname")}
                className=""
                size="small"
              />
            </div>
            <div className="pt-0px">
              <CustomTextField
                id="outlined-last-static"
                label=""
                placeholder={t("lastname")}
                className=""
                size="small"
              />
            </div>
            <div className="pt-0px">
              <CustomTextField
                id="outlined-country-static"
                label=""
                placeholder={t("country")}
                className=""
                size="small"
              />
            </div>
            <div className="pt-0px">
              <CustomTextField
                id="outlined-email-static"
                label=""
                placeholder={t("emailaddress")}
                className=""
                size="small"
              />
            </div>
          </div>
          <div className="mt-10px feedbackMsg">
            <CustomTextField
              id="outlined-type-static"
              label=""
              placeholder={t("typehere")}
              multiline
              className="w-100"
              rows={4}
            />
          </div>
        </CardContent>
      </Card>
      <Card className="mt-10px">
        <CardContent>
          <Typography className="feedbackInfo">{t("contactustext")}</Typography>
          <Grid container spacing={2} className="feedbackDetails mt-10px">
            <Grid item xs={12} lg={4}>
              <div className="d-flex justify-content-center align-items-center">
                <img src={phoneIcon} alt="phone"></img>
                <span>+237 242 01 01 16 / 661 599 290</span>
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              {" "}
              <div className="d-flex justify-content-center align-items-center">
                <img src={messageIcon} alt="msg"></img>
                <span>info@draftb.org</span>
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              {" "}
              <div className="d-flex justify-content-center align-items-center">
                <img src={boxIcon} alt="msg"></img>
                <span>2286 Yaoundé – Nsimeyong III (Carrefour TAM-TAM)</span>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default FeedbackPage;
