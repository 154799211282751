const feedbacktranslation = {
  20: {
    description: `Please leave any comments or feedback you may have. We are always
            interested to hear how we can improve your experience and care. You
            may leave a comment about the state of the health system near you or
            thoughts you have on how we are doing with addressing the key asks
            set by UNHLM.`,
    title: "Comments and Feedback",
    dropdownfeedback: "Select area for feedback",
    menuitem1: "Health System",
    menuitem2: "CRG",
    menuitem3: "UNHLM",
    firstname: "First Name..",
    lastname: "Last Name..",
    country: "Country..",
    emailaddress: "Email Address..",
    typehere: "Type Here..",
    contactustext:"If you would like to contact us directly please see the contact information below"
  },
  30: {
    description: `N’hésitez pas à laisser vos commentaires ou réactions. Nous sommes toujours
            Vous souhaitez savoir comment nous pouvons améliorer votre expérience et vos soins. Vous
            laisser un commentaire sur l’état du système de santé près de chez vous ;
            vos réflexions sur la façon dont nous répondons aux demandes clés
            établi par UNHLM.`,
    title: "Commentaires et réactions",
    dropdownfeedback: "Sélectionner la zone de rétroaction",
    menuitem1: "Système de santé",
    menuitem2: "Le CRG",
    menuitem3: "Réunion de haut niveau de l’UNHLM",
    firstname: "Prénom..",
    lastname: "Nom..",
    country: "Pays..",
    emailaddress: "Adresse courriel..",
    typehere: "Tapez ici..",
    contactustext:"Si vous souhaitez nous contacter directement, veuillez consulter les coordonnées ci-dessous"
  },
};

export default feedbacktranslation;
