const unlhmscorecardtranslation = {
  20: {
    card1description: `United Nations High Level Meeting TB Scorecard.`,
    selectcountry: "Select Country",
    card2description: "Go to Country Level UNHLM Scorecard",
  },
  30: {
    card1description: `Tableau de bord de la lutte contre la tuberculose de la Réunion de haut niveau des Nations Unies.`,
    selectcountry: "Sélectionner un pays",
    card2description:
      "Aller à la fiche d’évaluation de l’UNHLM au niveau national",
  },
};

export default unlhmscorecardtranslation;
