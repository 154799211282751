import React from "react";
import { Box } from "@mui/material";
import Sidebar from "../components/Sidebar";
import HomePage from "./HomePage/HomePage";

function Home() {
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar></Sidebar>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        className="mainStructurePage pt-0px"
      >
        <HomePage></HomePage>
      </Box>
    </Box>
  );
}

export default Home;
