import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

// icons import
import reachIcon from "../../assets/images/unhlmpageIcons/reach.svg";
import justiceIcon from "../../assets/images/unhlmpageIcons/justice.svg";
import medicineIcon from "../../assets/images/unhlmpageIcons/syringe.svg";
import fundingIcon from "../../assets/images/unhlmpageIcons/funding.svg";
import hospitalIcon from "../../assets/images/unhlmpageIcons/hospital.svg";
import worldIcon from "../../assets/images/unhlmpageIcons/worldwide.svg";
import UNHLMCountrySelect from "./UNHLMCountrySelect";
import { useLanguage } from "../../contexts/LanguageContext";
import unlhmtranslation from "../../config/unlhmtranslation";

function UNHLMPage() {
  const { language } = useLanguage();
  const t = (key) => unlhmtranslation[language][key] || key;

  return (
    <div className="commonPageCardSubSection unhlmPageCardSubSection">
      {/* first section */}
      <div className="">
        <Card>
          <CardContent>
            <div className="mt-10px">
              <Typography className="unhlmHeading">
                {" "}
                {t("card1description")}{" "}
              </Typography>
              <div className="keyPara mt-10px">
                <Card className="bg-pink">
                  <CardContent>
                    <div className="d-flex justify-content-start align-items-center">
                      <div>
                        <img src={reachIcon}></img>
                      </div>

                      <div className="ml-15px">
                        <Typography>{t("keyask")} # 1</Typography>
                        <p className="mb-0px keyParaSection">{t("card1")}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card className="bg-blue mt-10px">
                  <CardContent>
                    <div className="d-flex justify-content-start align-items-center">
                      <div>
                        <img src={justiceIcon}></img>
                      </div>

                      <div className="ml-15px">
                        <Typography>{t("keyask")} # 2</Typography>
                        <p className="mb-0px keyParaSection">{t("card2")}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card className="bg-orange mt-10px">
                  <CardContent>
                    <div className="d-flex justify-content-start align-items-center">
                      <div>
                        <img src={medicineIcon}></img>
                      </div>

                      <div className="ml-15px">
                        <Typography>{t("keyask")} # 3</Typography>
                        <p className="mb-0px keyParaSection">{t("card3")}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card className="bg-pink mt-10px">
                  <CardContent>
                    <div className="d-flex justify-content-start align-items-center">
                      <div>
                        <img src={fundingIcon}></img>
                      </div>

                      <div className="ml-15px">
                        <Typography>{t("keyask")} # 4</Typography>
                        <p className="mb-0px keyParaSection">{t("card4")}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card className="bg-blue mt-10px">
                  <CardContent>
                    <div className="d-flex justify-content-start align-items-center">
                      <div>
                        <img src={hospitalIcon}></img>
                      </div>

                      <div className="ml-15px">
                        <Typography>{t("keyask")} # 5</Typography>
                        <p className="mb-0px keyParaSection">{t("card5")}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card className="bg-orange mt-10px">
                  <CardContent>
                    <div className="d-flex justify-content-start align-items-center">
                      <div>
                        <img src={worldIcon}></img>
                      </div>

                      <div className="ml-15px">
                        <Typography>{t("keyask")} # 6</Typography>
                        <p className="mb-0px keyParaSection">{t("card6")}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* second section - community common country select dropdown Option */}
      <div className="mt-10px">
        <UNHLMCountrySelect></UNHLMCountrySelect>
      </div>
    </div>
  );
}

export default UNHLMPage;
