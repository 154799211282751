import { React } from "react";
import { Card, CardContent, Typography, IconButton, Grid } from "@mui/material";

// icons import
import greenArrowUpIcon from "../../assets/images/communitypageIcons/greenuoarrow.svg";
import lightRedDownArrowIcon from "../../assets/images/communitypageIcons/lightreddownarrow.svg";
import peopleUserIcon from "../../assets/images/communitypageIcons/people.svg";
import peopleComIcon from "../../assets/images/communitypageIcons/peoplegroup.svg";
import alertIcon from "../../assets/images/communitypageIcons/alert.svg";
import infoIcon from "../../assets/images/homepageIcons/infoicon.svg";
import CRGCountrySelect from "./CRGCountrySelect";
import { Height } from "@mui/icons-material";
import { useLanguage } from "../../contexts/LanguageContext";
import crgtranslation from "../../config/crgtranslation";

function CRGPage() {
  const { language } = useLanguage();
  const t = (key) => crgtranslation[language][key] || key;
  return (
    <div className="commonPageCardSubSection">
      {/* first section */}
      <div>
        <Card>
          <CardContent>
            <div>
              <Typography> {t("card1description")} </Typography>
              <div className="keyPara">
                <p className="pb-0px">
                  <ul>
                    <li className="mt-16px mb-16px">{t("card1")}</li>
                    <li className="mt-16px mb-16px">​ {t("card2")}</li>
                    <li className="mt-16px mb-16px">{t("card3")}</li>
                    <li className="mt-16px mb-16px">​ {t("card4")}</li>
                    <li className="mt-16px mb-16px">{t("card5")}</li>
                  </ul>
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="mt-10px d-none">
        <Card style={{ height: "200px" }}>
          <CardContent>
            <div>
              {" "}
              <Typography className="text-center">
                {" "}
                Tool Integration{" "}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="mt-10px">
        <CRGCountrySelect></CRGCountrySelect>
      </div>
    </div>
  );
}

export default CRGPage;
